.news-detail-section
	.date-share
		@apply flex items-center gap-10 mb-5
	.share
		@apply body-16
		@apply font-normal text-neutral-500 flex items-center gap-3
		i
			@apply text-primary-400
	.line
		@apply h-px bg-neutral-700 mb-10
	.box-other
		@apply xl:pl-8
