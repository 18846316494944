.contact-section
	.iframe-scale
		@apply pt-[calc(450/1312*100%)]
	.company-info
		@apply space-y-10
	.address-list
		@apply flex flex-wrap
		li
			@apply sub-header-20
			@apply font-bold text-neutral-900 flex gap-2 w-1/2
			i, em
				@apply text-optional-6 leading-normal
			& + li
				@apply mt-7
			&:first-child, &:nth-child(2)
				@apply w-full
	.contact-link
		@apply flex flex-wrap gap-4
		li
			@apply rounded-2 shadow-light overflow-hidden
	.wrap-form
		@apply relative
		.row
			@apply -mt-4 -mx-2
			> *
				@apply mt-4 px-2
		.form-group.frm-btnwrap
			@apply mt-4
	@screen lg
		.company-info
			@apply border-l border-l-neutral-200 pl-8 py-4
		.form-group
			&.frm-captcha
				@apply items-center
				.RadCaptcha
					@apply relative
					> span
						@apply absolute top-full left-0 mt-1
			&.frm-btnwrap
				@apply absolute bottom-0 right-0
