@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

@layer base {
  html, body {
    font-size: 16px;
    @apply scroll-pt-30 {} }
  @screen md {
    html, body {
      font-size: 16px; } }
  @screen xl {
    html, body {
      font-size: 1.25vw; } }
  @screen 2xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply font-sans overflow-y-overlay text-main {} }
  h1 {
    @apply text-3xl font-bold {} }
  h2 {
    @apply text-2xl font-bold {} }
  h3 {
    @apply text-xl font-bold {} }
  h4 {
    @apply text-lg font-bold {} }
  h5 {
    @apply text-base font-bold {} }
  h6 {
    @apply text-sm font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 hover:text-current {} }
  img {
    @apply max-w-full inline {} }
  main, div {
    @apply xl:text-base {} } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-full {} } }
  @screen sm {
    .container {
      @apply max-w-[540px] {} } }
  @screen md {
    .container {
      @apply max-w-[720px] {} } }
  @screen lg {
    .container {
      @apply max-w-[980px] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1440/1920*100rem)] {} } }
  @screen 2xl {
    .container {
      @apply max-w-[calc(1600/1920*100rem)] {} } }
  @media (min-width: 1600px) {
    .container {
      @apply max-w-[calc(1344/1920*100rem)] {} } }
  .equal-height .row > [class*=col] > * {
    @apply h-full {} }
  .equal-height .swiper-slide {
    @apply h-auto {} }
    .equal-height .swiper-slide > * {
      @apply h-full {} }
  .lozad-bg {
    @apply bg-center bg-no-repeat bg-cover {} } }

@layer utilities {
  .container-fuild {
    @apply px-4 {} }
  .section {
    @apply py-10 xl:py-16 {} }
  .section-t {
    @apply pt-10 xl:pt-16 {} }
  .section-b {
    @apply pb-10 xl:pb-16 {} }
  .section-small {
    @apply py-8 xl:py-10 {} }
  .section-t-small {
    @apply pt-8 xl:pt-10 {} }
  .section-b-small {
    @apply pb-8 xl:pb-10 {} }
  .section-large {
    @apply py-15 xl:py-20 {} }
  .section-t-large {
    @apply pt-15 xl:pt-20 {} }
  .section-b-large {
    @apply pb-15 xl:pb-20 {} }
  .row {
    @apply flex flex-wrap -mx-4 {} }
    .row > * {
      @apply px-4 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in:hover img {
    @apply scale-110 {} }
  .hover-underline {
    @apply relative {}    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms; }
    .hover-underline:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .hover-underline-active {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }
  .desktop-only {
    @apply hidden lg:block {} }
  .mobile-only {
    @apply block lg:hidden {} }
  .text-xs {
    @apply text-[14px] xl:text-[calc(12/1920*100rem)] {} }
  .img-cover {
    @apply relative overflow-hidden {} }
    .img-cover img {
      @apply w-full h-full object-cover transition-all {} }
  .img-contain {
    @apply relative overflow-hidden {} }
    .img-contain img {
      @apply w-full h-full object-contain transition-all {} }
  .img-zoom {
    @apply relative overflow-hidden {} }
    .img-zoom img {
      @apply transition-all {} }
    .img-zoom:hover img {
      @apply scale-110 {} } }

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300; }

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }

.header-48 {
  @apply text-4xl leading-1.33 xl:text-5xl {} }

.header-40 {
  @apply text-32 leading-1.2 xl:text-40 {} }

.section-header {
  @apply text-2xl leading-tight xl:text-32 {} }

.sub-header-24 {
  @apply text-xl leading-1.33 xl:text-2xl {} }

.sub-header-20 {
  @apply text-lg leading-1.3 xl:text-xl {} }

.body-16 {
  @apply text-base leading-tight {} }

.body-14 {
  @apply text-sm leading-tight {} }

.label-12 {
  @apply text-xs leading-1.33 {} }

.font-icon-32 {
  @apply text-32 leading-none {} }

.font-icon-16 {
  @apply text-base leading-none {} }

.site-title {
  @apply header-48 {}  @apply font-bold text-black {} }

.site-2-title {
  @apply header-40 {}  @apply font-bold text-black {} }

.site-desc {
  @apply text-base {} }

.full-content {
  @apply prose {}  @apply body-16 {}  @apply font-normal max-w-none {} }
  .full-content > * {
    @apply first:mt-0 {} }
  .full-content p img {
    @apply m-0 {} }

.button {
  @apply flex flex-wrap gap-5 {} }

.btn {
  @apply body-16 {}  @apply font-bold {} }

.btn-solid {
  @apply flex-center gap-2 bg-optional-7 text-white uppercase py-3 px-6 hover:bg-optional-3 hover:text-white {} }
  .btn-solid svg {
    @apply text-optional-7 transition-all {} }
  .btn-solid:hover svg {
    @apply text-optional-3 {} }
  .btn-solid.is-gray {
    @apply bg-neutral-400 hover:bg-optional-3 {} }
    .btn-solid.is-gray svg {
      @apply text-neutral-400 {} }
    .btn-solid.is-gray:hover svg {
      @apply text-optional-3 {} }

.btn-lined {
  @apply flex-center gap-2 bg-optional-7 text-white uppercase py-3 px-6 hover:bg-optional-3 hover:text-white {} }
  .btn-lined svg {
    @apply text-optional-7 transition-all {} }
  .btn-lined:hover svg {
    @apply text-optional-3 {} }
  .btn-lined.btn-light {
    @apply border border-white {} }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop {
  @apply fixed z-50 w-full h-full top-0 left-0 bg-black/80 pointer-events-none opacity-0 transition-all {} }
  .backdrop.open {
    @apply opacity-100 pointer-events-auto {} }

.nav-fixed {
  @apply fixed z-40 right-0 top-1/2 md:top-1/3 xl:top-28 xl:z-1000 {} }
  .nav-fixed ul {
    @apply space-y-2 {} }
  .nav-fixed li {
    @apply pointer-events-none {} }
    .nav-fixed li a {
      @apply body-14 {}      @apply font-bold text-white flex items-center shadow-medium bg-primary-700 translate-x-[calc(100%-(63/1920*100rem))] pointer-events-auto hover:bg-gradient-1 xl:hover:translate-x-0 {} }
    .nav-fixed li .icon {
      @apply flex-center w-16 h-16 text-32 p-2 {} }
    .nav-fixed li .text {
      @apply flex-1 pl-2 relative pr-2 hidden xl:block {} }
      .nav-fixed li .text::before {
        content: '';
        @apply absolute top-1/2 -translate-y-1/2 left-0 w-px h-9 bg-white z-1 block {} }
  .nav-fixed .back-to-top {
    @apply opacity-0 pointer-events-none {} }
    .nav-fixed .back-to-top.active {
      @apply opacity-100 pointer-events-auto {} }
  .nav-fixed .ActionMenu {
    left: auto !important;
    right: 0 !important; }

.social-list {
  @apply flex flex-wrap gap-2 {} }
  .social-list a {
    @apply w-12 h-12 rounded-full flex-center text-white text-xl bg-primary-600 hover:bg-gradient-1 {} }

.site-block-content {
  @apply flex flex-col gap-8 {} }

.site-flex {
  @apply flex justify-between flex-wrap gap-x-5 gap-y-3 {} }

.site-nav {
  @apply flex items-center flex-wrap gap-2 {} }
  .site-nav a {
    @apply body-16 {}    @apply font-medium text-white bg-neutral-400 uppercase py-2 px-4 rounded-full flex-center hover:bg-optional-3 {} }
  .site-nav li.active a {
    @apply bg-optional-3 {} }

.dropdown {
  @apply relative {} }
  .dropdown .dropdown-toggle {
    @apply body-16 {}    @apply font-bold text-white flex items-center justify-between gap-4 cursor-pointer {} }
    .dropdown .dropdown-toggle i {
      @apply text-lg {} }
  .dropdown .dropdown-menu {
    @apply absolute top-full right-0 w-full min-w-max z-10 bg-white shadow opacity-0 pointer-events-none transition-all {} }
    .dropdown .dropdown-menu li + li {
      @apply border-t border-t-neutral-200 {} }
    .dropdown .dropdown-menu a {
      @apply sub-header-20 {}      @apply font-normal text-black px-5 py-3 inline-block hover:text-primary-600 {} }
      .dropdown .dropdown-menu a:hover {
        text-shadow: 1px 0 0 currentColor; }
  .dropdown:hover .dropdown-menu {
    @apply opacity-100 pointer-events-auto {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
textarea,
select {
  @apply bg-white border border-neutral-200 pl-5 pr-10 py-2 rounded-none text-base font-normal leading-normal text-neutral-900 w-full bg-no-repeat h-10 m-0 {}  @apply focus:outline-none focus:ring-2 focus:ring-optional-1 focus:ring-offset-0 {} }

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'] {
  @apply placeholder:text-neutral-500 {}  @apply disabled:bg-neutral-700/50 disabled:border-neutral-700/75 disabled:text-neutral-900 {}  @apply read-only:bg-neutral-700/50 read-only:border-neutral-700/75 read-only:text-neutral-900 {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>");
  @apply bg-right appearance-none {} }

select[multiple] {
  @apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none {} }

textarea {
  @apply h-30 py-2 {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-neutral-600 border-none h-12 px-6 py-3 text-base text-primary-600 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-700 {} }

input[type='checkbox'] {
  @apply hidden {} }
  input[type='checkbox'] ~ label {
    @apply relative pl-5 {} }
    input[type='checkbox'] ~ label::before {
      @apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='checkbox']:checked ~ label::before {
    @apply content-['\f14a'] {} }

input[type='radio'] {
  @apply hidden {} }
  input[type='radio'] ~ label {
    @apply relative pl-5 {} }
    input[type='radio'] ~ label::before {
      @apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal {} }
  input[type='radio']:checked ~ label::before {
    @apply content-['\f192'] {} }

.form-group {
  @apply relative {} }
  .form-group > label {
    @apply relative block text-base font-normal leading-normal text-neutral-800 {} }
  .form-group [class*='fa-ex'] {
    @apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal {} }

.frm-btnwrap label.label, .frm-btnwrap .frm-btn-reset {
  @apply hidden {} }

.frm-btnwrap input[type='submit'] {
  @apply btn btn-solid rounded-full transition-all cursor-pointer {} }

.frm-captcha {
  @apply flex flex-wrap gap-5 mt-5 lg:flex-row-reverse lg:justify-end {} }
  .frm-captcha label {
    @apply hidden {} }
  .frm-captcha .frm-captcha-input {
    @apply w-full lg:w-auto {} }
    .frm-captcha .frm-captcha-input input {
      @apply w-full h-10 xl:max-w-[calc(260/1920*100rem)] {} }
  .frm-captcha .RadCaptcha {
    width: max-content !important;
    @apply flex flex-col-reverse {} }
    .frm-captcha .RadCaptcha > div {
      @apply w-full {} }
      .frm-captcha .RadCaptcha > div > div {
        @apply flex items-center {} }
    .frm-captcha .RadCaptcha > span {
      @apply text-[12px] text-optional-1 italic block mt-1 font-sans font-normal {} }
  .frm-captcha .rcRefreshImage {
    font-size: 0; }
    .frm-captcha .rcRefreshImage::before {
      @apply font-awesome content-['\f021'] font-light text-2xl text-neutral-400 ml-5 xl:text-40 {} }

.RadUpload {
  @apply max-w-full {} }
  .RadUpload input[type="file"] {
    @apply block {} }
  .RadUpload .ruInputs .ruRemove {
    @apply text-0 w-auto {} }
  .RadUpload .ruInputs li:not(:last-child) {
    @apply flex items-center {} }
  .RadUpload .ruInputs li:last-child {
    @apply mb-0 {} }
    .RadUpload .ruInputs li:last-child .ruFileWrap {
      @apply block w-full h-25 rounded-3 border border-dashed border-neutral-700 bg-white p-3 flex-center {} }
      .RadUpload .ruInputs li:last-child .ruFileWrap input {
        @apply w-full h-full bg-white border-none text-main text-center {} }

.apply-frm .apply-frm-title {
  @apply sub-header-24 {}  @apply font-bold text-black text-center py-4 bg-neutral-50 {} }

.apply-frm .apply-frm-wrap {
  @apply px-8 max-w-[calc(896/1920*100rem)] mx-auto py-5 md:py-10 {} }

.apply-frm .form-group {
  @apply mb-8 last:mb-0 {} }
  .apply-frm .form-group label {
    @apply text-base w-full text-main font-normal mb-1 {} }
    .apply-frm .form-group label span {
      @apply text-optional-1 text-sm {} }
  .apply-frm .form-group input:not([type="button"]), .apply-frm .form-group textarea {
    @apply border-neutral-200 {} }

.apply-frm .attachfile1 {
  @apply relative {} }
  .apply-frm .attachfile1 label {
    @apply h-[40px] border border-neutral-200 font-normal text-neutral-500 px-3 flex items-center text-base w-full max-w-[calc(100%-(32/1920*100rem))] m-0 absolute top-0 left-4 z-2 pointer-events-none bg-white {} }
  .apply-frm .attachfile1 .RadUpload {
    @apply w-full rounded-none border-none max-w-full {} }
    .apply-frm .attachfile1 .RadUpload .ruInputs {
      @apply flex flex-col-reverse {} }
      .apply-frm .attachfile1 .RadUpload .ruInputs li:last-child .ruFileWrap {
        @apply h-[40px] rounded-none border-none cursor-pointer {} }

.apply-frm .frm-btn {
  @apply w-full flex justify-end {} }

.apply-frm .wrap-form {
  @apply relative {} }

.apply-frm .form-group input {
  @apply md:h-[40px] {} }

.apply-frm .form-group textarea {
  @apply md:h-[120px] {} }

@screen md {
  .apply-frm .form-group.frm-captcha {
    @apply items-center flex-row-reverse justify-end {} }
    .apply-frm .form-group.frm-captcha .frm-captcha-input {
      @apply w-auto {} }
    .apply-frm .form-group.frm-captcha .RadCaptcha {
      @apply relative {} }
      .apply-frm .form-group.frm-captcha .RadCaptcha > span {
        @apply absolute top-full left-0 mt-1 {} }
  .apply-frm .form-group.frm-btnwrap {
    @apply absolute bottom-0 right-0 {} } }

@screen lg {
  .apply-frm .form-group {
    @apply flex gap-8 {} }
    .apply-frm .form-group label {
      @apply w-[30%] mb-0 text-base {} }
    .apply-frm .form-group input:not([type="button"]) {
      @apply h-10 text-base {} }
    .apply-frm .form-group textarea {
      @apply h-25 text-base {} } }

.modal {
  @apply bg-transparent bg-none p-0 {} }
  .modal .modal-wrap {
    @apply bg-white p-5 rounded-2 shadow {} }

.modal-apply {
  @apply w-full max-w-[calc(1088/1920*100rem)] {} }
  .modal-apply .modal-wrap {
    @apply p-0 rounded-none shadow-none {} }
  .modal-apply .iframe-scale {
    @apply pt-[75%] lg:pt-[calc(640/1088*100%)] {} }

.pagination {
  @apply flex-center flex-wrap gap-3 mt-10 xl:mt-15 {} }
  .pagination span, .pagination a {
    @apply flex-center bg-white font-normal text-primary-800 text-base w-10 h-10 rounded-1 border border-primary-800 hover:bg-primary-800 hover:text-white {} }
  .pagination li.active span, .pagination li.active a {
    @apply bg-primary-800 text-white {} }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper-pagination .swiper-pagination-bullet {
  @apply opacity-100 w-3 h-3 rounded-full bg-optional-1/50 {} }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-optional-1 {} }

.swiper-pagination.is-white .swiper-pagination-bullet {
  @apply bg-white/50 {} }
  .swiper-pagination.is-white .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-white {} }

.swiper-button > * {
  @apply text-2xl text-primary-600 w-15 h-15 rounded-2 border border-primary-600 bg-transparent flex-center transition-all cursor-pointer hover:text-white hover:bg-primary-600 {} }
  .swiper-button > *.swiper-button-disabled {
    @apply opacity-40 cursor-default hover:text-primary-600 hover:bg-transparent {} }
  .swiper-button > *.swiper-button-lock {
    @apply opacity-0 pointer-events-none {} }

.swiper-button.is-white > * {
  @apply text-white bg-white/20 border-white hover:text-neutral-200 {} }

.swiper-button.is-absolute > * {
  @apply absolute top-1/2 -translate-y-1/2 z-1 {} }

.swiper-button.is-absolute .button-prev {
  @apply left-1 {} }

.swiper-button.is-absolute .button-next {
  @apply right-1 {} }

.swiper-button.is-absolute.is-top-20 > * {
  @apply top-[20%] {} }

.swiper-button.is-absolute.is-top-30 > * {
  @apply top-[30%] {} }

.swiper-button.is-absolute.is-top-40 > * {
  @apply top-[40%] {} }

.swiper-button.is-angle > * {
  @apply text-32 text-neutral-500 border-none w-auto h-auto rounded-none hover:text-primary-600 hover:bg-transparent {} }

.swiper-button.is-angle.is-absolute .button-prev {
  @apply -left-10 {} }

.swiper-button.is-angle.is-absolute .button-next {
  @apply -right-10 {} }

.swiper-relative {
  @apply relative lg:px-10 lg:pb-0 xl:px-0 {} }
  .swiper-relative .swiper-button.is-absolute .button-prev {
    @apply left-0 xl:-left-12 2xl:-left-22 {} }
  .swiper-relative .swiper-button.is-absolute .button-next {
    @apply right-0 xl:-right-12 2xl:-right-22 {} }
  .swiper-relative .swiper-scrollbar {
    @apply h-[2px] bg-neutral-300 {} }
    .swiper-relative .swiper-scrollbar .swiper-scrollbar-drag {
      @apply bg-optional-1 {} }

.table-responsive {
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08);
  @apply rounded-5 bg-white shadow w-full overflow-x-auto {} }
  .table-responsive table {
    @apply w-full min-w-[1000px] {} }
  .table-responsive th {
    @apply sub-header-20 {}    @apply font-bold text-primary-600 text-left p-7 last:text-right {} }
  .table-responsive td {
    @apply body-16 {}    @apply font-normal text-black py-5 px-7 align-top last:text-right {} }
    .table-responsive td:last-child {
      @apply text-right {} }
      .table-responsive td:last-child .link-download {
        @apply justify-end {} }
    .table-responsive td > a {
      @apply hover-underline hover:text-primary-600 {} }
  .table-responsive .load-more-table-item.show {
    display: table-row !important; }
  @media (max-width: 1023.89px) {
    .table-responsive {
      @apply shadow-none rounded-none {} }
      .table-responsive table {
        @apply min-w-0 {} }
      .table-responsive thead {
        @apply hidden {} }
      .table-responsive tr, .table-responsive th, .table-responsive td {
        @apply block px-5 py-2 {} }
      .table-responsive tr {
        box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08);
        @apply rounded-5 bg-white shadow w-full px-3 {} }
        .table-responsive tr + tr {
          @apply mt-5 {} }
      .table-responsive td {
        @apply last:flex last:gap-1 {} }
        .table-responsive td::before {
          content: attr(data-title) ": ";
          @apply sub-header-20 {}          @apply font-bold text-primary-600 text-left {} } }

@screen lg {
  .table-responsive.is-interleaved {
    @apply p-0 shadow-none rounded-none {} }
    .table-responsive.is-interleaved th {
      @apply border-b border-b-primary-600 py-3 first:px-5 {} }
    .table-responsive.is-interleaved td {
      @apply px-7 border-b-[10px] border-b-white py-3 {} }
    .table-responsive.is-interleaved tr {
      @apply bg-neutral-50 even:bg-neutral-100 {} } }

@screen lg {
  .table-responsive.is-doc {
    @apply p-0 shadow-none rounded-none {} }
    .table-responsive.is-doc table {
      @apply w-full min-w-full {} }
    .table-responsive.is-doc th {
      @apply bg-primary-600 text-white py-3 h-12 first:px-5 first:rounded-l-1 last:rounded-r-1 {} }
    .table-responsive.is-doc td {
      @apply px-7 py-3 align-top first:rounded-l-1 last:rounded-r-1 {} }
    .table-responsive.is-doc tr {
      @apply bg-neutral-100 even:bg-neutral-50 {} }
    .table-responsive.is-doc .link-download {
      @apply text-neutral-500 {} }
      .table-responsive.is-doc .link-download a {
        @apply text-0 before:text-xl {} }
      .table-responsive.is-doc .link-download span {
        @apply hidden {} } }

@screen lg {
  .table-responsive.is-pro-1 {
    @apply p-0 shadow-none rounded-none {} }
    .table-responsive.is-pro-1 table {
      @apply w-full min-w-full {} }
    .table-responsive.is-pro-1 th {
      @apply bg-primary-600 py-3 h-12 text-white text-left first:px-5 {} }
    .table-responsive.is-pro-1 td {
      @apply px-7 py-4 align-top text-left {} }
    .table-responsive.is-pro-1 tbody tr {
      @apply border-b border-b-primary-300 {} } }

@screen lg {
  .table-responsive.is-recruitment {
    @apply p-0 shadow-none rounded-none {} }
    .table-responsive.is-recruitment table {
      @apply w-full min-w-full {} }
    .table-responsive.is-recruitment th {
      @apply bg-primary-600 text-white py-3 h-12 first:pl-8 last:text-center {} }
    .table-responsive.is-recruitment td {
      @apply px-7 py-3 align-top last:text-center first:pl-8 {} }
    .table-responsive.is-recruitment tr {
      @apply bg-neutral-50 even:bg-neutral-100 {} } }

.table-power-output {
  @apply w-full overflow-x-auto {} }
  .table-power-output table {
    @apply w-full min-w-[1200px] {} }
  .table-power-output th {
    @apply body-16 {}    @apply font-bold text-white bg-primary-600 text-center border border-white p-2 {} }
    .table-power-output th:first-child {
      @apply font-bold bg-white border-b-primary-600 text-primary-600 {} }
  .table-power-output td {
    @apply body-14 {}    @apply font-normal text-black bg-primary-100 text-center border border-white p-2 {} }
    .table-power-output td:first-child {
      @apply body-16 {}      @apply font-bold text-white bg-primary-600 {} }
    .table-power-output td.total {
      @apply bg-primary-300 {} }

.site-nav-sticky {
  @apply sticky top-24 md:top-20 z-40 xl:top-25 {} }

.site-nav-toggle {
  @apply body-16 {}  @apply font-bold text-white bg-primary-500 py-3 px-6 flex justify-between {} }

@screen lg {
  .site-nav-toggle {
    @apply hidden {} } }

.site-nav-list {
  @apply absolute top-full left-0 w-full z-10 bg-white flex flex-col px-5 opacity-0 pointer-events-none {} }
  .site-nav-list a {
    @apply body-16 {}    @apply font-medium text-neutral-600 inline-block py-3 hover-underline hover:text-optional-3 {} }
  .site-nav-list li + li {
    @apply border-t border-t-neutral-200 {} }
  .site-nav-list li.active a {
    @apply text-optional-3 {} }
  .site-nav-list.open {
    @apply opacity-100 pointer-events-auto {} }

@screen lg {
  .site-nav-list {
    @apply static bg-white px-0 flex-row gap-5 opacity-100 pointer-events-auto pt-1 {} }
    .site-nav-list li {
      @apply border-none {} }
    .site-nav-list.open {
      @apply opacity-100 pointer-events-auto {} } }

@screen xl {
  .site-nav-list {
    @apply justify-center {} } }

@screen 2xl {
  .site-nav-list {
    @apply gap-10 {} } }

.about-bg {
  background-position: center 0 !important;
  background-size: contain !important; }

.about-1-section .site-block-content {
  @apply gap-6 lg:py-15 lg:pr-4 {} }

.about-1-section .image {
  @apply aspect-[640/460] {} }

.about-2-item {
  @apply relative text-current flex flex-col gap-5 {} }
  .about-2-item .year {
    @apply sub-header-24 {}    @apply font-bold text-black text-center {} }
  .about-2-item .line {
    @apply relative w-[3px] mx-auto h-20 bg-current {} }
    .about-2-item .line::before, .about-2-item .line::after {
      content: '';
      @apply w-4 h-4 rounded-full bg-current absolute left-1/2 -translate-x-1/2 pointer-events-none {} }
    .about-2-item .line::before {
      @apply top-0 {} }
    .about-2-item .line::after {
      @apply bottom-0 {} }
  .about-2-item .brief {
    @apply body-16 {}    @apply font-normal text-black text-center {} }

@screen lg {
  .about-2-item {
    @apply my-[calc(250/1920*100rem)] {} }
    .about-2-item .line {
      @apply h-[calc(176/1920*100rem)] relative -top-20 {} }
    .about-2-item .year {
      @apply absolute top-full left-1/2 -translate-x-1/2 -mt-18 w-full {} }
    .about-2-item .brief {
      @apply absolute bottom-full left-1/2 -translate-x-1/2 w-full mb-25 {} } }

.about-2-swiper {
  @apply relative {} }
  .about-2-swiper .swiper-line {
    @apply absolute top-[18%] -translate-y-1/2 left-0 w-full h-[2px] bg-neutral-400 pointer-events-none lg:top-[15%] {} }
  .about-2-swiper .swiper-slide:nth-child(6n+1) {
    @apply text-optional-1 {} }
  .about-2-swiper .swiper-slide:nth-child(6n+2) {
    @apply text-optional-2 {} }
  .about-2-swiper .swiper-slide:nth-child(6n+3) {
    @apply text-optional-3 {} }
  .about-2-swiper .swiper-slide:nth-child(6n+4) {
    @apply text-optional-4 {} }
  .about-2-swiper .swiper-slide:nth-child(6n+5) {
    @apply text-optional-5 {} }
  .about-2-swiper .swiper-slide:nth-child(6n+6) {
    @apply text-optional-6 {} }

@screen lg {
  .about-2-swiper .swiper-line {
    @apply top-1/2 before:left-0 after:right-0 {} }
    .about-2-swiper .swiper-line:before, .about-2-swiper .swiper-line::after {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-neutral-400 {} }
  .about-2-swiper .swiper-slide:nth-child(even) .about-2-item {
    @apply flex-col-reverse {} }
    .about-2-swiper .swiper-slide:nth-child(even) .about-2-item .line {
      @apply top-20 {} }
    .about-2-swiper .swiper-slide:nth-child(even) .about-2-item .year {
      @apply top-auto bottom-full -mb-18 mt-0 {} }
    .about-2-swiper .swiper-slide:nth-child(even) .about-2-item .brief {
      @apply top-full bottom-auto mb-0 mt-25 {} }
  .about-2-swiper .swiper-button.is-absolute .button-prev {
    @apply -left-10 {} }
  .about-2-swiper .swiper-button.is-absolute .button-next {
    @apply -right-10 {} } }

.about-3-section .main-tabslet {
  @apply bg-white p-5 max-w-[calc(640/1920*100rem)] {} }

.about-3-section .tabslet-tabs {
  @apply flex items-center gap-5 {} }
  .about-3-section .tabslet-tabs a {
    @apply sub-header-20 {}    @apply font-bold text-neutral-300 hover-underline hover:text-primary-600 {} }
  .about-3-section .tabslet-tabs li {
    @apply relative last:before:hidden {} }
    .about-3-section .tabslet-tabs li::before {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 -right-2 h-5 w-[2px] bg-neutral-300 pointer-events-none {} }
    .about-3-section .tabslet-tabs li.active a {
      @apply text-primary-600 {} }

.about-3-section .tabslet-content {
  @apply mt-4 {} }

.about-3-section .full-content {
  @apply body-16 {}  @apply font-normal text-black {} }

.about-3-section .about-3-wrap {
  @apply md:pb-[calc(250/1920*100rem)] {} }

@screen lg {
  .about-3-section .main-tabslet {
    @apply py-10 {} } }

.about-5-item h3 {
  @apply sub-header-24 font-bold text-black mb-7 {} }

.accordion-item {
  @apply border-b border-b-neutral-200 {} }
  .accordion-item .accordion-title {
    @apply body-16 {}    @apply font-bold text-neutral-500 uppercase py-4 cursor-pointer {} }
  .accordion-item .accordion-content {
    @apply hidden body-16 font-normal text-black mb-4 {} }
  .accordion-item.active .accordion-title {
    @apply text-primary-600 {} }

.about-5-section .image {
  @apply aspect-[640/460] {} }

.about-6-item {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @apply bg-primary-50 p-4 flex justify-center flex-col gap-2 text-center {} }
  .about-6-item .title {
    @apply body-16 {}    @apply font-bold text-primary-600 {} }
  .about-6-item .brief {
    @apply body-16 {}    @apply font-normal text-black {} }

.about-7-item {
  @apply flex flex-col gap-8 sm:flex-row sm:items-center {} }
  .about-7-item .image {
    @apply aspect-[303/228] sm:w-[calc(304/640*100%)] {} }
  .about-7-item .caption {
    @apply sm:flex-1 {} }
  .about-7-item .title {
    @apply sub-header-24 {}    @apply font-bold text-black mb-1 {} }
  .about-7-item .brief {
    @apply body-16 {}    @apply font-normal text-black {} }
  .about-7-item .button {
    @apply mt-6 {} }

.global-breadcrumb {
  @apply bg-white border-b border-b-neutral-100 {} }
  .global-breadcrumb .breadcrumb {
    @apply flex flex-wrap items-center py-3 gap-x-3 gap-y-2 {} }
    .global-breadcrumb .breadcrumb a {
      @apply body-16 {}      @apply block font-normal text-black {} }
      .global-breadcrumb .breadcrumb a.active {
        @apply text-optional-3 {} }
    .global-breadcrumb .breadcrumb li {
      @apply flex items-center last:after:hidden {} }
      .global-breadcrumb .breadcrumb li::after {
        @apply font-awesome content-['\f054'] pl-3 text-black text-base leading-none {} }

.banner-child .image {
  @apply aspect-[1920/500] {} }

.home-banner {
  @apply relative overflow-hidden {} }
  .home-banner .image {
    @apply aspect-[1920/700] {} }
  .home-banner .caption {
    @apply border-t border-t-current py-3 text-black {} }
  .home-banner .title {
    @apply sub-header-20 {}    @apply font-normal text-current {} }
  .home-banner .swiper-thumb {
    @apply px-4 py-3 {} }
    .home-banner .swiper-thumb .swiper {
      @apply max-w-[calc(1084/1920*100rem)] ml-0 {} }
    .home-banner .swiper-thumb .swiper-slide {
      @apply cursor-pointer {} }
  .home-banner .swiper-slide.swiper-slide-thumb-active .caption {
    @apply border-t-optional-7 text-primary-600 {} }
  .home-banner .footer-certificate {
    @apply absolute z-1 right-0 top-1/2 -translate-y-1/2 text-white {} }
    .home-banner .footer-certificate p {
      @apply mb-2 {} }

@screen lg {
  .home-banner .swiper-thumb {
    @apply absolute bottom-0 left-0 w-full pt-30 pb-6 bg-gradient-to-b from-black/0 to-black/80 pointer-events-none z-1 {} }
  .home-banner .caption {
    @apply text-white py-6 {} }
  .home-banner .swiper-slide {
    @apply pointer-events-auto {} }
    .home-banner .swiper-slide.swiper-slide-thumb-active .caption {
      @apply text-white {} } }

.contact-section .iframe-scale {
  @apply pt-[calc(450/1312*100%)] {} }

.contact-section .company-info {
  @apply space-y-10 {} }

.contact-section .address-list {
  @apply flex flex-wrap {} }
  .contact-section .address-list li {
    @apply sub-header-20 {}    @apply font-bold text-neutral-900 flex gap-2 w-1/2 {} }
    .contact-section .address-list li i, .contact-section .address-list li em {
      @apply text-optional-6 leading-normal {} }
    .contact-section .address-list li + li {
      @apply mt-7 {} }
    .contact-section .address-list li:first-child, .contact-section .address-list li:nth-child(2) {
      @apply w-full {} }

.contact-section .contact-link {
  @apply flex flex-wrap gap-4 {} }
  .contact-section .contact-link li {
    @apply rounded-2 shadow-light overflow-hidden {} }

.contact-section .wrap-form {
  @apply relative {} }
  .contact-section .wrap-form .row {
    @apply -mt-4 -mx-2 {} }
    .contact-section .wrap-form .row > * {
      @apply mt-4 px-2 {} }
  .contact-section .wrap-form .form-group.frm-btnwrap {
    @apply mt-4 {} }

@screen lg {
  .contact-section .company-info {
    @apply border-l border-l-neutral-200 pl-8 py-4 {} }
  .contact-section .form-group.frm-captcha {
    @apply items-center {} }
    .contact-section .form-group.frm-captcha .RadCaptcha {
      @apply relative {} }
      .contact-section .form-group.frm-captcha .RadCaptcha > span {
        @apply absolute top-full left-0 mt-1 {} }
  .contact-section .form-group.frm-btnwrap {
    @apply absolute bottom-0 right-0 {} } }

.cmsadminpanel .ddviewmode {
  @apply h-[24px] text-[12px] py-1 px-2 pr-5 leading-none {} }

.searchbox {
  @apply relative {} }
  .searchbox input {
    @apply w-full h-12 border-2 border-neutral-200 rounded-1 pl-5 pr-12 text-base text-neutral-100 bg-neutral-950 {}    @apply placeholder:text-neutral-200 {}    @apply focus:ring-primary-600 focus:ring-2 {} }
  .searchbox button {
    @apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-10 h-10 flex-center text-xl text-white bg-primary-600 rounded-1 transition-all hover:bg-primary-800 {} }

.suggestsearch {
  display: none !important; }

.searchcontrols {
  @apply relative {} }
  .searchcontrols .form-inline {
    @apply relative {} }
  .searchcontrols .form-group {
    @apply hidden first:block last:block last:absolute last:w-12 last:h-full last:top-0 last:right-0 last:z-1 last:text-[0] last:mb-0 last:flex-center {}    @apply last:before:absolute last:before:w-full last:before:h-full last:before:font-awesome last:before:content-['\f002'] last:before:text-blue-500 last:before:text-2xl last:before:flex-center last:before:pointer-events-none {} }
    .searchcontrols .form-group:first-child input {
      @apply pr-14 {} }
    .searchcontrols .form-group:last-child input {
      @apply z-2 w-12 h-full relative top-0 right-0 opacity-0 {} }
  .searchcontrols .seachpage-btn {
    @apply text-base px-4 absolute w-max h-full top-0 right-0 z-1 flex-center text-white bg-primary-600 rounded-1 transition-all hover:bg-primary-800 {} }

.searchresults {
  @apply mt-12 {} }
  .searchresults .search-page {
    @apply py-15 xl:py-20 {} }
  .searchresults .page-header h1 {
    @apply uppercase {}    @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px {} }
  .searchresults .searchresultsummary {
    @apply text-[11px] xl:text-[13px] mb-3 {} }
  .searchresults .searchresults > .modulepager {
    @apply hidden {} }
    .searchresults .searchresults > .modulepager:last-child {
      @apply block {} }
  .searchresults .searchresultlist {
    @apply px-1 xl:px-5 {} }
  .searchresults .searchresult {
    @apply mt-10 {} }
    .searchresults .searchresult h3 a {
      @apply text-xl hover-underline hover:text-primary-600 {} }
    .searchresults .searchresult .searchresultdesc {
      @apply mt-1 text-base {} }
    .searchresults .searchresult hr {
      @apply hidden {} }

.login-box {
  @apply py-25 px-10 xl:py-30 {} }
  .login-box .card {
    @apply bg-neutral-200 mx-auto p-5 {} }

@screen xl {
  .login-box .card {
    @apply max-w-[calc(750/1920*100rem)] px-[calc(95/1920*100rem)] pt-7 pb-15 {} } }
  .login-box .input-group {
    @apply mb-5 {} }
    .login-box .input-group input {
      @apply border-white bg-white w-full rounded-1 h-12 text-neutral-700 text-base px-5 placeholder:text-neutral-200 {} }
  .login-box .input-group-append {
    @apply hidden {} }
  .login-box .btn.btn-primary-600.btn-block, .login-box .btn.btn-default.createuserbutton {
    @apply cursor-pointer bg-black border-black transition-all h-11 text-center text-white font-normal uppercase text-sm px-10 rounded-full xl:min-w-[calc(183/1920*100rem)] xl:hover:bg-primary-600 xl:hover:border-primary-600 {} }
  .login-box .social-login {
    @apply hidden {} }
  .login-box .row.items-center {
    @apply justify-between {} }
  .login-box a {
    @apply text-base xl:text-sm {} }

.wrap-register > div > div {
  @apply shadow rounded-5 overflow-hidden bg-white mx-auto p-5 {} }

@screen xl {
  .wrap-register > div > div {
    @apply max-w-[calc(600/1920*100rem)] p-7 {} } }

.wrap-register .settingrow .RadInput .riTextBox {
  @apply w-full bg-neutral-400 border border-solid border-neutral-400 rounded-1 h-12 px-5 text-base text-neutral-900 leading-normal xl:text-sm {} }

.login-logo, .pagetitle {
  @apply text-2xl font-light text-optional-1 uppercase leading-tight text-center mb-8 {} }

.login-box-msg {
  @apply text-base leading-[1.38] text-optional-1 font-normal mb-5 {} }

.icheck-primary-600 {
  @apply form-group text-base space-x-3 {} }

.login-download-section .login-box {
  @apply p-0 xl:p-0 {} }
  .login-download-section .login-box .btn-download-submit {
    @apply w-full {} }

.login-download-section .module-description {
  @apply text-base text-optional-1 font-normal {} }

.box .box-head {
  @apply bg-primary-600 px-6 py-4 {} }

.box .box-title {
  @apply section-header {}  @apply font-bold text-white {} }

.box .box-body {
  @apply border-x border-b border-neutral-200 {} }

.box-category .category-list a {
  @apply sub-header-20 {}  @apply font-normal text-black inline-block px-7 py-5 hover:text-primary-600 {} }

.box-category .category-list li + li {
  @apply border-t border-t-neutral-200 {} }

.box-category .category-list li.active a {
  @apply text-primary-600 font-bold {} }

.document-section .site-title {
  @apply pb-5 pt-2 border-b border-b-neutral-200 {} }

.production-section .dvt {
  @apply body-16 {}  @apply font-bold text-optional-3 {} }

@media (max-width: 1023.89px) {
  .production-section .table-responsive {
    @apply overflow-visible {} } }

.foa-item {
  @apply relative {} }
  .foa-item .image {
    @apply aspect-[316/227] {} }
  .foa-item .caption {
    @apply absolute bottom-0 left-0 z-1 w-full bg-gradient-to-b from-black/0 to-black/50 p-5 pt-19 pointer-events-none {} }
    .foa-item .caption a {
      @apply pointer-events-auto {} }
  .foa-item .title {
    @apply sub-header-20 {}    @apply font-bold text-white {} }

.foa-nav-swiper .swiper-slide {
  @apply w-max {} }

.foa-nav-swiper .foa-dt-link {
  @apply w-max {} }
  .foa-nav-swiper .foa-dt-link a {
    @apply body-16 {}    @apply font-medium text-neutral-500 whitespace-nowrap block pb-3 hover-underline hover:text-optional-3 {} }
    .foa-nav-swiper .foa-dt-link a.active {
      @apply text-optional-3 hover-underline-active {} }

@screen lg {
  .foa-nav-swiper {
    @apply px-7 {} }
    .foa-nav-swiper .swiper-button.is-absolute > * {
      @apply text-xl text-neutral-500 top-[35%] {} }
    .foa-nav-swiper .swiper-button.is-absolute .button-prev {
      @apply left-0 {} }
    .foa-nav-swiper .swiper-button.is-absolute .button-next {
      @apply right-0 {} } }

.foa-detail-swiper {
  @apply max-w-[calc(1088/1920*100rem)] mx-auto {} }
  .foa-detail-swiper .swiper {
    @apply overflow-visible {} }
  .foa-detail-swiper .image {
    @apply aspect-[1088/782] bg-white opacity-50 transition-all {} }
  .foa-detail-swiper .swiper-slide-active .image {
    @apply opacity-100 {} }

.foa-section {
  @apply overflow-hidden {} }

.news-detail-section .date-share {
  @apply flex items-center gap-10 mb-5 {} }

.news-detail-section .share {
  @apply body-16 {}  @apply font-normal text-neutral-500 flex items-center gap-3 {} }
  .news-detail-section .share i {
    @apply text-primary-400 {} }

.news-detail-section .line {
  @apply h-px bg-neutral-700 mb-10 {} }

.news-detail-section .box-other {
  @apply xl:pl-8 {} }

.news-date {
  @apply body-16 {}  @apply font-medium text-neutral-500 {} }

.category-date {
  @apply body-16 {}  @apply font-medium text-neutral-500 flex gap-3 {} }
  .category-date .category {
    @apply relative {} }
    .category-date .category::before {
      content: '|';
      @apply absolute top-1/2 -translate-y-1/2 -right-2 pointer-events-none {} }

.news-item {
  @apply flex flex-col {} }
  .news-item .image {
    @apply aspect-[416/299] {} }
  .news-item .caption {
    @apply h-full flex flex-col gap-2 pl-3 pr-7 py-5 {} }
  .news-item .title {
    @apply sub-header-20 {}    @apply font-bold text-black line-clamp-2 hover:text-primary-600 {} }
  .news-item .brief {
    @apply body-16 {}    @apply font-normal text-black mt-2 line-clamp-2 {} }
  .news-item.is-hor {
    @apply flex-row gap-0 {} }
    .news-item.is-hor + .news-item {
      @apply mt-5 lg:mt-8 {} }
    .news-item.is-hor .image {
      @apply w-[calc(160/1920*100rem)] aspect-[160/120] {} }
    .news-item.is-hor .caption {
      @apply flex-1 p-0 pl-5 border-b border-b-neutral-200 h-auto lg:pl-8 {} }
    .news-item.is-hor .title {
      @apply line-clamp-3 {} }
  .news-item.is-big .image {
    @apply aspect-[624/448] {} }
  .news-item.is-big .brief {
    @apply line-clamp-3 {} }

@screen lg {
  .news-item.is-big .title {
    @apply section-header {}    @apply font-semibold text-neutral-950 hover:text-primary-600 {} }
  .news-item.is-big .caption {
    @apply px-0 py-8 {} } }
  .news-item.is-other .caption {
    @apply px-1 {} }

@screen lg {
  .news-list .col-l {
    @apply pr-8 {} }
  .news-list .col-r {
    @apply pl-8 border-l border-l-neutral-200 {} } }

.site-nav-animation {
  @apply -mt-5 {} }
  .site-nav-animation ul {
    @apply flex-center gap-3 mb-10 xl:gap-6 xl:mb-15 {} }
    .site-nav-animation ul > li {
      @apply relative {} }
      .site-nav-animation ul > li > a {
        border: solid 4px theme("colors.optional.9");
        @apply flex-center body-16 font-bold text-optional-3 bg-optional-9 px-5 py-2 rounded-full shadow-light text-center {}        @apply hover:text-primary-600 {} }
        .site-nav-animation ul > li > a.is-animation {
          --border-angle: 0turn;
          --main-bg: conic-gradient(from var(--border-angle),#FFF500,#FFF500 5%,#FFF500 60%,#FFF500 95%);
          border: solid 4px transparent;
          border-radius: 2em;
          --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #1B5EB2, #E41913 99%, transparent);
          background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
          background-position: center center;
          animation: bg-spin 3s linear infinite;
          @apply shadow-none {} }

@keyframes bg-spin {
  to {
    --border-angle: 1turn; } }
          .site-nav-animation ul > li > a.is-animation:hover {
            animation-play-state: paused; }
      .site-nav-animation ul > li:hover > ul {
        @apply opacity-100 pointer-events-auto {} }
      .site-nav-animation ul > li > ul {
        @apply flex-col justify-start items-start mb-0 gap-0 absolute top-full left-0 w-max bg-white shadow z-10 py-3 rounded-1 transition-all duration-200 ease-in-out opacity-0 pointer-events-none {} }
        .site-nav-animation ul > li > ul li {
          @apply w-full before:hidden {} }
        .site-nav-animation ul > li > ul a {
          @apply border-0 bg-none bg-transparent justify-start shadow-none text-neutral-950 transition-all duration-200 ease-in-out text-left hover:text-optional-3 {} }

@screen xl {
  .site-nav-animation li + li {
    @apply relative {} }
    .site-nav-animation li + li::before {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 -left-3 pointer-events-none w-px h-5 bg-neutral-200 block {} } }

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn; }

header {
  @apply sticky top-0 left-0 w-full z-999 bg-white transition-all shadow-light {} }
  header .header-wrap {
    @apply flex px-0 {} }
  header .header-left {
    @apply w-1/4 max-w-[calc(212/1920*100rem)] bg-optional-9 p-4 flex-center {} }
  header .header-right {
    @apply flex-1 {} }
  header .header-top {
    @apply flex items-center flex-wrap gap-4 p-2 {} }
  header .header-bot {
    @apply bg-neutral-50 h-full hidden {} }
  header .company-name {
    @apply sub-header-20 {}    @apply font-bold text-optional-3 text-[10px] {} }
  header .company-sub-name {
    @apply body-16 {}    @apply font-medium text-primary-600 -mt-[2px] text-[9px] {} }
  header .header-language-search {
    @apply flex items-center justify-end gap-4 w-full {} }
  header .language-list {
    @apply flex items-center gap-4 {} }
    header .language-list a {
      @apply body-16 {}      @apply font-normal text-neutral-500 hover-underline {} }
    header .language-list li.active a {
      @apply text-primary-600 {} }
  header .header-search {
    @apply relative ml-4 w-8 h-8 rounded-1 flex-center text-primary-600 cursor-pointer hover:text-primary-700 transition-all hover:bg-neutral-100 {} }
    header .header-search::before {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 -left-4 w-px h-3 bg-neutral-300/50 pointer-events-none {} }
  header .main-menu {
    @apply flex items-center justify-between {} }
    header .main-menu a {
      @apply body-16 {}      @apply font-bold text-black flex-center h-full py-2 hover-underline uppercase hover:text-optional-3 {} }
    header .main-menu li.active > a {
      @apply text-optional-3 hover-underline-active {} }
    header .main-menu li.has-dropdown {
      @apply relative {} }
      header .main-menu li.has-dropdown:hover .dropdown-menu {
        @apply opacity-100 pointer-events-auto translate-y-0 {} }
    header .main-menu .dropdown-menu {
      @apply absolute top-full left-0 w-max bg-white space-y-2 px-3 py-8 opacity-0 pointer-events-none -translate-y-3 transition-all shadow {} }
      header .main-menu .dropdown-menu a {
        @apply body-16 {}        @apply justify-start {} }
  header.active {
    @apply shadow {} }

@screen sm {
  header .header-left {
    @apply w-1/3 {} }
  header .company-name {
    @apply text-[13px] {} }
  header .company-sub-name {
    @apply text-[12px] {} } }

@screen md {
  header .header-wrap {
    @apply px-4 items-center {} }
  header .header-left {
    @apply w-1/5 {} }
  header .header-top {
    @apply flex-nowrap justify-between p-0 {} }
  header .header-caption {
    @apply static p-0 pl-5 bg-transparent {} }
  header .header-language-search {
    @apply w-auto {} } }

@screen lg {
  header .header-left {
    @apply w-[calc(212/1312*100%)] h-25 {} }
  header .header-right {
    @apply border-r border-r-neutral-200 flex flex-col {} }
  header .header-top {
    @apply py-2 px-8 justify-between {} }
  header .header-bot {
    @apply block px-8 relative {} }
    header .header-bot::before {
      content: '';
      @apply absolute bottom-0 left-0 w-full z-1 h-px bg-neutral-200 pointer-events-none {} }
  header .header-caption {
    @apply block p-0 {} }
  header .company-name {
    @apply sub-header-20 {} }
  header .company-sub-name {
    @apply body-16 {} }
  header .navbar-nav {
    @apply relative z-2 {} }
  header .main-menu > li > a {
    @apply h-10 {} } }

@screen 2xl {
  header .main-menu li {
    @apply relative last:before:hidden {} }
    header .main-menu li::before {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 -right-6 w-1 h-1 bg-neutral-200 pointer-events-none {} }
    header .main-menu li li {
      @apply before:hidden {} } }

.mobile-wrap {
  @apply fixed top-0 w-screen h-screen bg-white shadow z-998 pt-28 pl-4 pb-4 max-w-xs -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none lg:hidden {} }
  .mobile-wrap .header-caption {
    @apply pr-5 {} }
  .mobile-wrap .company-name {
    @apply sub-header-20 {}    @apply font-bold text-optional-3 {} }
  .mobile-wrap .company-sub-name {
    @apply body-16 {}    @apply font-medium text-primary-600 -mt-[2px] {} }
  .mobile-wrap .navbar-nav-list {
    @apply pr-4 mt-5 {} }
  .mobile-wrap .main-menu a {
    @apply body-16 {}    @apply font-bold text-black flex py-2 uppercase {} }
  .mobile-wrap .main-menu li.active > a {
    @apply text-optional-3 {} }
  .mobile-wrap .main-menu li.has-dropdown .dropdown-menu {
    @apply pl-5 mb-3 {} }
    .mobile-wrap .main-menu li.has-dropdown .dropdown-menu a {
      @apply body-14 text-[13px] normal-case {} }
  .mobile-wrap.open {
    @apply left-0 opacity-100 pointer-events-auto {} }

.search-wrap {
  @apply fixed top-1/3 -translate-y-1/2 left-1/2 -translate-x-1/2 w-full max-w-md z-998 px-6 opacity-0 pointer-events-none transition {} }
  .search-wrap.open {
    @apply opacity-100 pointer-events-auto {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-primary-600 rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1 {} }
  #buttonMenu .bar {
    @apply bg-primary-600 rounded-1 absolute shadow-bar {} }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.open .line:nth-child(2), #buttonMenu:hover .line:nth-child(2) {
    @apply w-[15px] {} }
  #buttonMenu.open .bar, #buttonMenu:hover .bar {
    @apply hidden {} }

@screen lg {
  #buttonMenu {
    display: none !important; } }

.library-item {
  @apply relative cursor-pointer {} }
  .library-item .image {
    @apply relative aspect-[256/192] bg-black {} }
    .library-item .image img {
      @apply opacity-70 {} }
  .library-item .caption {
    @apply absolute bottom-0 left-0 w-full bg-black/30 px-5 py-3 text-center pointer-events-none {} }
  .library-item .title {
    text-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    @apply sub-header-20 {}    @apply font-bold text-white line-clamp-2 {} }
    .library-item .title a {
      @apply pointer-events-auto {} }
  .library-item.is-video .image {
    @apply aspect-[192/128] bg-black {} }
    .library-item.is-video .image::before {
      @apply absolute-center font-awesome content-['\f04b'] font-thin text-5xl text-white z-2 {} }
    .library-item.is-video .image img {
      @apply opacity-60 {} }
  .library-item.is-video .caption {
    @apply static bg-none bg-transparent px-0 py-2 {} }
  .library-item.is-video .title {
    @apply text-neutral-950 hover:text-primary-600 {} }

@screen lg {
  .library-item .caption {
    @apply px-8 {} } }

.library-list {
  @apply grid grid-cols-2 gap-4 {} }

@screen md {
  .library-list {
    @apply gap-8 grid-cols-3 {} } }

@screen xl {
  .library-list.is-video {
    @apply grid-cols-2 {} } }

.library-section .tabslet-tabs {
  @apply flex justify-center gap-5 md:gap-10 {} }
  .library-section .tabslet-tabs a {
    @apply sub-header-20 text-black font-bold hover:text-optional-3 {} }
  .library-section .tabslet-tabs li.active a {
    @apply hover-underline-active text-optional-3 {} }

.home-about-item {
  @apply bg-white py-8 px-4 border-b-2 border-b-primary-600 text-center {} }
  .home-about-item .number {
    @apply header-48 {}    @apply font-normal text-primary-600 {} }
  .home-about-item .title {
    @apply body-16 {}    @apply font-medium text-neutral-950 mt-8 pt-8 relative {} }
    .home-about-item .title::before {
      content: '';
      @apply absolute left-1/2 -translate-x-1/2 top-0 w-10 h-px bg-primary-600 {} }
  .home-about-item .sub-title {
    @apply sub-header-20 {}    @apply font-bold text-primary-600 mt-1 {} }

.home-result-item {
  @apply bg-white border-2 border-neutral-200 p-8 flex flex-col justify-between {} }
  .home-result-item .title {
    @apply sub-header-20 {}    @apply font-bold text-primary-600 text-center {} }
  .home-result-item .chart-line-wrap {
    @apply mt-10 text-center {} }
  .home-result-item .caption {
    @apply pb-8 flex-1 {} }
  .home-result-item .percent {
    @apply sub-header-24 {}    @apply font-medium text-black {} }
  .home-result-item .sub-percent {
    @apply body-16 {}    @apply font-normal text-black mt-1 {} }

@screen lg {
  .home-result-item {
    @apply px-11 py-8 {} } }

.highcharts-item {
  @apply h-[300px] {} }
  .highcharts-item .highcharts-title {
    @apply sub-header-20 !important {}    @apply font-bold text-primary-600 fill-primary-600 text-center text-xl !important {} }
  .highcharts-item .highcharts-legend-item {
    @apply text-sm {} }
  .highcharts-item .highcharts-credits {
    display: none !important; }

.home-result .site-title {
  @apply normal-case {} }

.home-quicklink .site-title {
  @apply text-white {} }

.home-shareholder-item {
  @apply bg-white p-8 flex flex-col gap-2 {} }
  .home-shareholder-item .icon {
    @apply text-5xl text-primary-600 {} }
  .home-shareholder-item .title {
    @apply sub-header-24 {}    @apply font-bold text-primary-600 {} }
  .home-shareholder-item .brief {
    @apply body-16 {}    @apply font-normal text-black {} }
    .home-shareholder-item .brief a {
      @apply hover-underline hover:text-primary-600 {} }

.home-shareholder {
  @apply min-h-screen flex flex-col justify-between gap-10 {} }
  .home-shareholder .site-title, .home-shareholder .site-desc {
    @apply text-white {} }
  .home-shareholder .site-desc {
    @apply section-header {}    @apply font-light {} }
  .home-shareholder .site-block-content {
    @apply max-w-[calc(547/1920*100rem)] {} }

.home-video-block {
  @apply bg-white p-5 md:p-6 {} }
  .home-video-block .library-list {
    @apply md:gap-x-4 md:gap-y-6 {} }
    .home-video-block .library-list .library-item.is-video .caption {
      @apply pb-0 {} }

.home-gallery-item {
  @apply flex flex-col gap-5 xl:gap-8 {} }
  .home-gallery-item .title {
    @apply sub-header-20 text-black font-bold uppercase hover:text-optional-3 xl:text-center xl:text-balance {} }
  .home-gallery-item .image {
    @apply aspect-[4/5] {} }
  .home-gallery-item .caption {
    @apply flex-1 {} }

@screen xl {
  .home-gallery-item:hover .title {
    @apply text-optional-3 {} }
  .home-gallery-item:hover .image img {
    @apply scale-110 {} } }

.home-gallery-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-4 {} }

.home-library .home-gallery-wrap {
  @apply flex flex-col xl:pr-8 {} }

.home-library .home-gallery-list {
  @apply mt-8 xl:mt-10 {} }

.home-library .home-video-wrap {
  @apply xl:-ml-8 {} }

.home-library .tabslet-tabs {
  @apply flex gap-5 {} }
  .home-library .tabslet-tabs a {
    @apply sub-header-20 text-black font-bold uppercase hover:text-optional-3 {} }
  .home-library .tabslet-tabs li.active a {
    @apply hover-underline-active text-optional-3 {} }

.home-zone-item {
  @apply relative {} }
  .home-zone-item::before {
    content: '';
    @apply absolute-center w-full h-full z-1 bg-gradient-to-b from-black/0 to-black/30 pointer-events-none {} }
  .home-zone-item .image {
    @apply aspect-[1164/848] overflow-hidden {} }
  .home-zone-item .title {
    @apply header-40 {}    @apply font-bold text-white absolute bottom-0 left-0 w-full px-5 py-3 z-2 {} }
  .home-zone-item.is-small + .home-zone-item {
    @apply mt-1 {} }
  .home-zone-item.is-small .image {
    @apply aspect-[752/280] {} }

.home-zone {
  @apply pb-1 {} }
  .home-zone > .row {
    @apply gap-1 {} }
  .home-zone .home-zone-list {
    @apply h-[calc(500/1920*100rem)] overflow-x-hidden overflow-y-auto {} }

@screen md {
  .home-zone > .row {
    @apply flex-nowrap {} } }

@screen lg {
  .home-zone .col-l {
    @apply w-[calc(1164/1920*100%)] {} }
    .home-zone .col-l .home-zone-item {
      @apply h-full overflow-hidden {} }
      .home-zone .col-l .home-zone-item .image {
        @apply h-full {} }
  .home-zone .col-r {
    @apply w-[calc(752/1920*100%)] {} }
  .home-zone .home-zone-list {
    @apply h-[calc(540/1920*100rem)] {} } }

@screen xl {
  .home-zone .home-zone-list {
    @apply h-[calc(848/1920*100rem)] {} } }

.power-output-today {
  @apply sub-header-24 font-bold text-optional-3 text-center mb-8 {} }

.service-1-item {
  @apply relative {} }
  .service-1-item .image {
    @apply aspect-[480/324] {} }
    .service-1-item .image img {
      @apply w-full h-full object-cover {} }
  .service-1-item .caption {
    @apply relative z-1 -mt-17 flex flex-col gap-3 justify-center px-5 py-3 bg-primary-600/80 {} }
  .service-1-item .title {
    @apply sub-header-24 font-bold text-white {}    @apply leading-[1.33] h-10 line-clamp-1 flex items-center {} }
  .service-1-item .desc {
    @apply body-14 font-normal text-white {}    @apply leading-[1.28] pt-5 {} }
    .service-1-item .desc ul {
      @apply list-disc {} }
      .service-1-item .desc ul li {
        @apply marker:text-[10px] {} }
  .service-1-item.is-video {
    @apply cursor-pointer {} }
    .service-1-item.is-video::before {
      @apply absolute-center z-1 pointer-events-none font-awesome content-['\f04b'] text-32 font-thin text-white xl:text-64 transition-all duration-200 ease-in-out {} }
    .service-1-item.is-video .caption {
      @apply pointer-events-none {} }

@screen xl {
  .service-1-item .caption {
    @apply absolute bottom-0 left-0 w-full h-[107px] py-2 px-10 m-0 transition-all duration-200 ease-in-out {} }
    .service-1-item .caption:hover {
      @apply h-full {} }
      .service-1-item .caption:hover .toggle-hidden {
        grid-template-rows: 1fr; }
  .service-1-item .title {
    @apply h-auto line-clamp-2 {} }
  .service-1-item .desc {
    @apply pt-0 {} } }

@screen xl {
  .toggle-hidden {
    grid-template-rows: 0fr;
    transition: 250ms grid-template-rows ease;
    @apply grid {} }
    .toggle-hidden > div {
      @apply overflow-hidden {} } }

.service-1-list {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-5 lg:grid-cols-3 xl:grid-cols-4 xl:gap-0 {} }

.service-detail-section .site-title {
  @apply header-40 text-primary-600 {} }

.service-detail-section .image {
  @apply aspect-[640/432] {} }

.service-detail-section .block-content {
  @apply lg:max-w-[544px] lg:ml-auto lg:mr-0 {} }

.service-detail-section .full-content {
  @apply body-16 text-black {} }
  .service-detail-section .full-content ul {
    @apply pl-4 {} }
  .service-detail-section .full-content li {
    @apply marker:text-sm marker:text-black {} }

.service-detail-other-section .site-title {
  @apply header-40 text-primary-600 {} }

@screen xl {
  .service-detail-other-section .service-1-item .title, .service-detail-other-section .service-1-item .desc {
    @apply max-w-[395px] mx-auto {} } }

.sitemap {
  @apply py-15 {} }

.sitemap-heading h1 {
  @apply site-title text-center uppercase mx-auto {}  @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px before:bg-gradient {} }

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root a {
    @apply inline-flex items-center px-7 py-3 text-xl bg-blue-500 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-optional-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > a {
    @apply before:absolute before:left-full before:w-[120%] before:h-px before:bg-neutral-700 before:-z-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
    @apply hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root li {
    @apply pl-3 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li a {
      @apply bg-blue-500/90 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li a {
      @apply bg-blue-500/75 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li li a {
      @apply bg-blue-500/60 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
    @apply before:absolute before:w-px before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
    @apply before:top-full {} }
  .AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
    @apply before:hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
    @apply mt-2 relative z-1 {}    @apply after:absolute after:w-px after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-neutral-700 after:pointer-events-none {} }
    .AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
      @apply relative {}      @apply before:absolute before:w-3 before:-z-1 before:h-px before:bg-neutral-700 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none {} }

.welfare-item {
  @apply flex items-center gap-2 {} }

.welfare-list {
  @apply grid grid-cols-2 gap-4 xl:grid-cols-3 {} }

.recruitment-other-item {
  @apply flex flex-col gap-4 {} }
  .recruitment-other-item + .recruitment-other-item {
    @apply mt-4 {} }
  .recruitment-other-item .title {
    @apply body-16 {}    @apply font-normal text-black line-clamp-2 hover:text-optional-3 {} }
    .recruitment-other-item .title:hover {
      text-shadow: 1px 0 0 currentColor; }
  .recruitment-other-item ul {
    @apply flex flex-wrap gap-x-6 gap-y-3 {} }
  .recruitment-other-item li {
    @apply body-16 {}    @apply font-normal text-neutral-400 flex items-center gap-2 {} }

.box-recruitment-other .box-title {
  @apply body-16 {} }

.box-recruitment-other .box-body {
  @apply p-5 xl:px-8 {} }

.recruitment-detail-section .recruitment-image {
  @apply aspect-[640/460] {} }

.recruitment-detail-section .recruitment-information table {
  @apply w-full {} }

.recruitment-detail-section .recruitment-information td, .recruitment-detail-section .recruitment-information th {
  @apply body-16 {}  @apply font-normal text-neutral-900 py-4 border-b border-b-neutral-50 align-top first:w-1/3 first:font-bold first:pr-1 xl:first:w-1/4 {} }

.recruitment-detail-section .recruitment-wrap + .recruitment-wrap {
  @apply mt-11 {} }

.recruitment-detail-section .recruitment-attributes {
  @apply body-16 {}  @apply font-normal text-black {} }
  .recruitment-detail-section .recruitment-attributes > * {
    @apply mb-2 last:mb-0 {} }
  .recruitment-detail-section .recruitment-attributes h3 {
    @apply sub-header-20 {}    @apply font-bold text-primary-600 mb-1 {} }
  .recruitment-detail-section .recruitment-attributes h4 {
    @apply sub-header-24 {}    @apply font-bold text-black uppercase mt-6 mb-2 {} }
  .recruitment-detail-section .recruitment-attributes + .recruitment-attributes {
    @apply mt-8 {} }
  .recruitment-detail-section .recruitment-attributes + .button {
    @apply mt-10 gap-8 {} }

.recruitment-detail-section .box-recruitment-other {
  @apply xl:mt-10 xl:sticky xl:top-30 {} }

.recruitment-detail-page .banner-child {
  @apply hidden {} }

.recruitment-1-section .image {
  @apply aspect-[720/518] {} }

.recruitment-2-section .site-block {
  @apply max-w-[calc(1098/1920*100rem)] mx-auto {} }

.recruitment-2-section .site-title, .recruitment-2-section .site-desc {
  @apply text-white {} }

.recruitment-2-section .site-desc {
  @apply sub-header-24 {}  @apply font-normal {} }

.recruitment-3-item {
  @apply text-center flex flex-col gap-8 {} }
  .recruitment-3-item .icon {
    @apply flex-center {} }
  .recruitment-3-item .title {
    @apply body-16 {}    @apply font-bold text-neutral-900 {} }
  .recruitment-3-item .sub-title {
    @apply body-16 {}    @apply font-normal text-neutral-900 {} }

.recruitment-page .news-list {
  @apply hidden {} }

.shareholder-detail-section .sort-year {
  @apply flex items-center gap-4 {} }
  .shareholder-detail-section .sort-year label {
    @apply sub-header-20 {}    @apply font-bold text-neutral-900 {} }
  .shareholder-detail-section .sort-year select {
    @apply sub-header-20 {}    @apply font-normal text-neutral-600 bg-neutral-50 border-neutral-50 rounded-none pl-5 pr-12 h-13 w-max {} }

.heading-flex {
  @apply flex flex-wrap justify-between gap-5 {} }

.link-download {
  @apply sub-header-20 {}  @apply font-normal text-optional-7 relative {} }
  .link-download a {
    @apply whitespace-nowrap flex items-center gap-2 hover:text-primary-600 {} }
    .link-download a::before {
      @apply font-awesome content-['\f019'] font-light text-2xl leading-none mr-1 {} }

.shareholder-item {
  @apply p-5 bg-white shadow-light rounded-5 flex flex-col justify-between gap-5 xl:min-h-[calc(304/1920*100rem)] {} }
  .shareholder-item .top {
    @apply flex flex-col gap-5 {} }
  .shareholder-item .icon {
    @apply h-15 flex justify-start {} }
    .shareholder-item .icon svg {
      @apply h-full {} }
  .shareholder-item .title {
    @apply body-16 {}    @apply font-normal text-black hover:text-primary-600 {} }
    .shareholder-item .title a {
      @apply hover-underline {} }

@screen lg {
  .shareholder-item {
    @apply px-10 py-7 {} }
    .shareholder-item .icon {
      @apply h-23 {} } }

@screen xl {
  .shareholder-item.is-hor {
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08);
    @apply flex-col min-h-0 p-7 pb-6 {} }
    .shareholder-item.is-hor .top {
      @apply flex-row gap-5 items-start {} }
    .shareholder-item.is-hor .icon {
      @apply h-15 {} }
    .shareholder-item.is-hor .link-download {
      @apply text-sm text-neutral-500 {} }
      .shareholder-item.is-hor .link-download a {
        @apply before:text-sm {} } }

@screen xl {
  .shareholder-1-section .table-responsive {
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08); }
    .shareholder-1-section .table-responsive td {
      @apply first:whitespace-nowrap {} } }

.shareholder-3-item {
  @apply flex flex-col gap-10 bg-white {} }
  .shareholder-3-item .image {
    @apply aspect-[375/540] {} }
  .shareholder-3-item .content {
    @apply flex-1 p-5 pb-12 {} }
  .shareholder-3-item .heading-flex {
    @apply mb-10 {} }

@screen md {
  .shareholder-3-item {
    @apply flex-row items-center {} }
    .shareholder-3-item .image {
      @apply w-[calc(375/1920*100rem)] {} }
    .shareholder-3-item .content {
      @apply p-0 pr-7 {} } }

.shareholder-3-box {
  @apply bg-neutral-50 {} }
  .shareholder-3-box:nth-child(2n) {
    @apply bg-white {} }
    .shareholder-3-box:nth-child(2n) .shareholder-3-item {
      @apply bg-primary-600 md:flex-row-reverse {} }
      .shareholder-3-box:nth-child(2n) .shareholder-3-item .section-header {
        @apply text-white {} }

@screen md {
  .shareholder-3-box:nth-child(2n) .shareholder-3-item .content {
    @apply p-0 pl-7 {} } }

.footer-content {
  @apply body-16 {}  @apply font-medium text-current space-y-4 {} }
  .footer-content h4 {
    @apply sub-header-20 font-bold {} }
  .footer-content address {
    @apply not-italic {} }
  .footer-content p a {
    @apply font-bold hover-underline {} }

.footer-menu {
  @apply space-y-4 {} }
  .footer-menu a {
    @apply sub-header-20 {}    @apply font-bold text-current hover-underline {} }

.footer-dropdown .dropdown .dropdown-toggle {
  @apply body-16 {}  @apply font-bold text-white bg-primary-500 py-3 px-6 {} }

.footer-dropdown .dropdown .dropdown-menu {
  @apply max-h-[calc(200/1920*100rem)] overflow-y-auto w-full max-w-full min-w-full shadow {} }
  .footer-dropdown .dropdown .dropdown-menu a {
    @apply text-sm whitespace-normal {} }
    .footer-dropdown .dropdown .dropdown-menu a:hover {
      text-shadow: none; }

.footer-policy ul {
  @apply space-y-3 {} }

.footer-policy a {
  @apply body-16 {}  @apply font-medium text-current hover-underline {} }

.footer-image ul {
  @apply flex flex-wrap gap-3 {} }

footer {
  @apply text-white py-15 xl:pt-16 xl:pb-30 {} }
  footer .col-line {
    @apply relative {} }
    footer .col-line::before {
      content: '';
      @apply absolute top-0 h-full w-px bg-white/30 -left-4 pointer-events-none {} }
    footer .col-line::after {
      content: '';
      @apply absolute top-0 h-11 w-[2px] bg-optional-3 -left-4 pointer-events-none z-1 {} }
  footer .footer-dropdown + * {
    @apply mt-4 {} }
  footer .footer-policy + * {
    @apply mt-3 {} }
