.home-about-item
	@apply bg-white py-8 px-4 border-b-2 border-b-primary-600 text-center
	.number
		@apply header-48
		@apply font-normal text-primary-600
	.title
		@apply body-16
		@apply font-medium text-neutral-950 mt-8 pt-8 relative
		&::before
			content: ''
			@apply absolute left-1/2 -translate-x-1/2 top-0 w-10 h-px bg-primary-600
	.sub-title
		@apply sub-header-20
		@apply font-bold text-primary-600 mt-1

.home-about

.home-result-item
	@apply bg-white border-2 border-neutral-200 p-8 flex flex-col justify-between
	.title
		@apply sub-header-20
		@apply font-bold text-primary-600 text-center
	.chart-line-wrap
		@apply mt-10 text-center
	// .chart-img
	// 	@apply w-22
	.caption
		@apply pb-8 flex-1
	.percent
		@apply sub-header-24
		@apply font-medium text-black
	.sub-percent
		@apply body-16
		@apply font-normal text-black mt-1
	@screen lg
		@apply px-11 py-8

.highcharts-item
	@apply h-[300px]
	.highcharts-title
		@apply sub-header-20 #{!important}
		@apply font-bold text-primary-600 fill-primary-600 text-center text-xl #{!important}
	.highcharts-legend-item
		@apply text-sm
	.highcharts-credits
		display: none !important

.home-result
	.site-title
		@apply normal-case

.home-quicklink
	.site-title
		@apply text-white

.home-shareholder-item
	@apply bg-white p-8 flex flex-col gap-2
	.icon
		@apply text-5xl text-primary-600
	.title
		@apply sub-header-24
		@apply font-bold text-primary-600
	.brief
		@apply body-16
		@apply font-normal text-black
		a
			@apply hover-underline hover:text-primary-600

.home-shareholder
	@apply min-h-screen flex flex-col justify-between gap-10
	.site-title, .site-desc
		@apply text-white
	.site-desc
		@apply section-header
		@apply font-light
	.site-block-content
		@apply max-w-[calc(547/1920*100rem)]

.home-video-block
	@apply bg-white p-5 md:p-6
	.library-list
		@apply md:gap-x-4 md:gap-y-6
		.library-item.is-video .caption
			@apply pb-0

.home-gallery-item
	@apply flex flex-col gap-5 xl:gap-8
	.title
		@apply sub-header-20 text-black font-bold uppercase hover:text-optional-3 xl:text-center xl:text-balance
	.image
		@apply aspect-[4/5]
	.caption
		@apply flex-1
	@screen xl
		&:hover
			.title
				@apply text-optional-3
			.image
				img
					@apply scale-110

.home-gallery-list
	@apply grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-4

.home-library
	.home-gallery-wrap
		@apply flex flex-col xl:pr-8
		// @screen xl
		// 	.button
		// 		@apply mt-10
	.home-gallery-list
		@apply mt-8 xl:mt-10
	.home-video-wrap
		@apply xl:-ml-8
	.tabslet-tabs
		@apply flex gap-5
		a
			@apply sub-header-20 text-black font-bold uppercase hover:text-optional-3
		li
			&.active
				a
					@apply hover-underline-active text-optional-3

.home-zone-item
	@apply relative
	&::before
		content: ''
		@apply absolute-center w-full h-full z-1 bg-gradient-to-b from-black/0 to-black/30 pointer-events-none
	.image
		@apply aspect-[1164/848] overflow-hidden
	.title
		@apply header-40
		@apply font-bold text-white absolute bottom-0 left-0 w-full px-5 py-3 z-2
	&.is-small
		& + .home-zone-item
			@apply mt-1
		.image
			@apply aspect-[752/280]

.home-zone
	@apply pb-1
	> .row
		@apply gap-1
	.home-zone-list
		@apply h-[calc(500/1920*100rem)] overflow-x-hidden overflow-y-auto
	@screen md
		> .row
			@apply flex-nowrap
	@screen lg
		.col-l
			@apply w-[calc(1164/1920*100%)]
			.home-zone-item
				@apply h-full overflow-hidden
				.image
					@apply h-full
		.col-r
			@apply w-[calc(752/1920*100%)]
		.home-zone-list
			@apply h-[calc(540/1920*100rem)]
	@screen xl
		.home-zone-list
			@apply h-[calc(848/1920*100rem)]
