.table-responsive
	box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08)
	@apply rounded-5 bg-white shadow w-full overflow-x-auto
	table
		@apply w-full min-w-[1000px]
	th
		@apply sub-header-20
		@apply font-bold text-primary-600 text-left p-7 last:text-right
	td
		@apply body-16
		@apply font-normal text-black py-5 px-7 align-top last:text-right
		&:last-child
			@apply text-right
			.link-download
				@apply justify-end
		> a
			@apply hover-underline hover:text-primary-600
	.load-more-table-item
		&.show
			display: table-row !important
	@media(max-width: 1023.89px)
		@apply shadow-none rounded-none
		table
			@apply min-w-0
		thead
			@apply hidden
		tr, th, td
			@apply block px-5 py-2
		tr
			box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08)
			@apply rounded-5 bg-white shadow w-full px-3
			& + tr
				@apply mt-5
		td
			@apply last:flex last:gap-1
			&::before
				content: attr(data-title) ': '
				@apply sub-header-20
				@apply font-bold text-primary-600 text-left
	&.is-interleaved
		@screen lg
			@apply p-0 shadow-none rounded-none
			th
				@apply border-b border-b-primary-600 py-3 first:px-5
			td
				@apply px-7 border-b-[10px] border-b-white py-3
			tr
				@apply bg-neutral-50 even:bg-neutral-100
	&.is-doc
		@screen lg
			@apply p-0 shadow-none rounded-none
			table
				@apply w-full min-w-full
			th
				@apply bg-primary-600 text-white py-3 h-12 first:px-5 first:rounded-l-1 last:rounded-r-1
			td
				@apply px-7 py-3 align-top first:rounded-l-1 last:rounded-r-1
			tr
				@apply bg-neutral-100 even:bg-neutral-50
			.link-download
				@apply text-neutral-500
				a
					@apply text-0 before:text-xl
				span
					@apply hidden
	&.is-pro-1
		@screen lg
			@apply p-0 shadow-none rounded-none
			table
				@apply w-full min-w-full
			th
				@apply bg-primary-600 py-3 h-12 text-white text-left first:px-5
			td
				@apply px-7 py-4 align-top text-left
			tbody
				tr
					@apply border-b border-b-primary-300
	&.is-recruitment
		@screen lg
			@apply p-0 shadow-none rounded-none
			table
				@apply w-full min-w-full
			th
				@apply bg-primary-600 text-white py-3 h-12 first:pl-8 last:text-center
			td
				@apply px-7 py-3 align-top last:text-center first:pl-8
			tr
				@apply bg-neutral-50 even:bg-neutral-100


.table-power-output
	@apply w-full overflow-x-auto
	table
		@apply w-full min-w-[1200px]
	th
		@apply body-16
		@apply font-bold text-white bg-primary-600 text-center border border-white p-2
		&:first-child
			@apply font-bold bg-white border-b-primary-600 text-primary-600
	td
		@apply body-14
		@apply font-normal text-black bg-primary-100 text-center border border-white p-2
		&:first-child
			@apply body-16
			@apply font-bold text-white bg-primary-600
		&.total
			@apply bg-primary-300

