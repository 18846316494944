.button
	@apply flex flex-wrap gap-5

.link

.btn
	@apply body-16
	@apply font-bold

.btn-solid
	@apply flex-center gap-2 bg-optional-7 text-white uppercase py-3 px-6 hover:bg-optional-3 hover:text-white
	svg
		@apply text-optional-7 transition-all
	&:hover
		svg
			@apply text-optional-3
	&.is-gray
		@apply bg-neutral-400 hover:bg-optional-3
		svg
			@apply text-neutral-400
		&:hover
			svg
				@apply text-optional-3

.btn-lined
	@apply flex-center gap-2 bg-optional-7 text-white uppercase py-3 px-6 hover:bg-optional-3 hover:text-white
	svg
		@apply text-optional-7 transition-all
	&:hover
		svg
			@apply text-optional-3
	&.btn-light
		@apply border border-white

