.header-48
	@apply text-4xl leading-1.33 xl:text-5xl

.header-40
	@apply text-32 leading-1.2 xl:text-40

.section-header
	@apply text-2xl leading-tight xl:text-32

.sub-header-24
	@apply text-xl leading-1.33 xl:text-2xl

.sub-header-20
	@apply text-lg leading-1.3 xl:text-xl

.body-16
	@apply text-base leading-tight

.body-14
	@apply text-sm leading-tight

.label-12
	@apply text-xs leading-1.33

.font-icon-32
	@apply text-32 leading-none

.font-icon-16
	@apply text-base leading-none

.site-title
	@apply header-48
	@apply font-bold text-black

.site-2-title
	@apply header-40
	@apply font-bold text-black

.site-desc
	@apply text-base

.full-content
	@apply prose
	@apply body-16
	@apply font-normal max-w-none
	> *
		@apply first:mt-0
	p
		img
			@apply m-0
