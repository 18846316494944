// alert
.alert
	@apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm
	& button
		@apply hidden
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb

.backdrop
	@apply fixed z-50 w-full h-full top-0 left-0 bg-black/80 pointer-events-none opacity-0 transition-all
	&.open
		@apply opacity-100 pointer-events-auto

.nav-fixed
	@apply fixed z-40 right-0 top-1/2 md:top-1/3 xl:top-28 xl:z-1000
	ul
		@apply space-y-2
	li
		@apply pointer-events-none
		a
			@apply body-14
			@apply font-bold text-white flex items-center shadow-medium bg-primary-700 translate-x-[calc(100%-(63/1920*100rem))] pointer-events-auto hover:bg-gradient-1 xl:hover:translate-x-0
		.icon
			@apply flex-center w-16 h-16 text-32 p-2
		.text
			@apply flex-1 pl-2 relative pr-2 hidden xl:block
			&::before
				content: ''
				@apply absolute top-1/2 -translate-y-1/2 left-0 w-px h-9 bg-white z-1 block
	.back-to-top
		@apply opacity-0 pointer-events-none
		&.active
			@apply opacity-100 pointer-events-auto
	.ActionMenu
		left: auto !important
		right: 0 !important

.social-list
	@apply flex flex-wrap gap-2
	a
		@apply w-12 h-12 rounded-full flex-center text-white text-xl bg-primary-600 hover:bg-gradient-1

.site-block-content
	@apply flex flex-col gap-8

.site-flex
	@apply flex justify-between flex-wrap gap-x-5 gap-y-3

.site-nav
	@apply flex items-center flex-wrap gap-2
	a
		@apply body-16
		@apply font-medium text-white bg-neutral-400 uppercase py-2 px-4 rounded-full flex-center hover:bg-optional-3
	li
		&.active
			a
				@apply bg-optional-3
