.home-banner
	@apply relative overflow-hidden
	.image
		@apply aspect-[1920/700]
	.caption
		@apply border-t border-t-current py-3 text-black
	.title
		@apply sub-header-20
		@apply font-normal text-current
	.swiper-thumb
		@apply px-4 py-3
		.swiper
			@apply max-w-[calc(1084/1920*100rem)] ml-0
		.swiper-slide
			@apply cursor-pointer
	.swiper-slide
		&.swiper-slide-thumb-active
			.caption
				@apply border-t-optional-7 text-primary-600
	.footer-certificate
		@apply absolute z-1 right-0 top-1/2 -translate-y-1/2 text-white
		p
			@apply mb-2
	@screen lg
		.swiper-thumb
			@apply absolute bottom-0 left-0 w-full pt-30 pb-6 bg-gradient-to-b from-black/0 to-black/80 pointer-events-none z-1
		.caption
			@apply text-white py-6
		.swiper-slide
			@apply pointer-events-auto
			&.swiper-slide-thumb-active
				.caption
					@apply text-white
