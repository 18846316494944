@layer components
	.container
		@screen xs
			@apply max-w-full
		@screen sm
			@apply max-w-[540px]
		@screen md
			@apply max-w-[720px]
		@screen lg
			@apply max-w-[980px]
		@screen xl
			@apply max-w-[calc(1440/1920*100rem)]
		@screen 2xl
			@apply max-w-[calc(1600/1920*100rem)]
		@media(min-width: 1600px)
			@apply max-w-[calc(1344/1920*100rem)]

	.equal-height
		.row > [class*=col] > *
			@apply h-full
		.swiper-slide
			@apply h-auto
			> *
				@apply h-full

	.lozad-bg
		@apply bg-center bg-no-repeat bg-cover
