.modal
	@apply bg-transparent bg-none p-0
	.modal-wrap
		@apply bg-white p-5 rounded-2 shadow

.modal-head

.modal-body

.modal-apply
	@apply w-full max-w-[calc(1088/1920*100rem)]
	.modal-wrap
		@apply p-0 rounded-none shadow-none
	.iframe-scale
		@apply pt-[75%] lg:pt-[calc(640/1088*100%)]
