.foa-item
	@apply relative
	.image
		@apply aspect-[316/227]
	.caption
		@apply absolute bottom-0 left-0 z-1 w-full bg-gradient-to-b from-black/0 to-black/50 p-5 pt-19 pointer-events-none
		a
			@apply pointer-events-auto
	.title
		@apply sub-header-20
		@apply font-bold text-white

.foa-nav-swiper
	.swiper-slide
		@apply w-max
	.foa-dt-link
		@apply w-max
		a
			@apply body-16
			@apply font-medium text-neutral-500 whitespace-nowrap block pb-3 hover-underline hover:text-optional-3
			&.active
				@apply text-optional-3 hover-underline-active
	@screen lg
		@apply px-7
		.swiper-button
			&.is-absolute
				> *
					@apply text-xl text-neutral-500 top-[35%]
				.button-prev
					@apply left-0
				.button-next
					@apply right-0

.foa-detail-swiper
	@apply max-w-[calc(1088/1920*100rem)] mx-auto
	.swiper
		@apply overflow-visible
	.image
		@apply aspect-[1088/782] bg-white opacity-50 transition-all
	.swiper-slide-active
		.image
			@apply opacity-100

.foa-section
	@apply overflow-hidden
