.footer-content
	@apply body-16
	@apply font-medium text-current space-y-4
	h4
		@apply sub-header-20 font-bold
	address
		@apply not-italic
	p
		a
			@apply font-bold hover-underline

.footer-menu
	@apply space-y-4
	a
		@apply sub-header-20
		@apply font-bold text-current hover-underline

.footer-dropdown
	.dropdown
		.dropdown-toggle
			@apply body-16
			@apply font-bold text-white bg-primary-500 py-3 px-6
		.dropdown-menu
			@apply max-h-[calc(200/1920*100rem)] overflow-y-auto w-full max-w-full min-w-full shadow
			a
				@apply text-sm whitespace-normal
				&:hover
					text-shadow: none

.footer-policy
	ul
		@apply space-y-3
	a
		@apply body-16
		@apply font-medium text-current hover-underline

.footer-image
	ul
		@apply flex flex-wrap gap-3

footer
	@apply text-white py-15 xl:pt-16 xl:pb-30
	.col-line
		@apply relative
		&::before
			content: ''
			@apply absolute top-0 h-full w-px bg-white/30 -left-4 pointer-events-none
		&::after
			content: ''
			@apply absolute top-0 h-11 w-[2px] bg-optional-3 -left-4 pointer-events-none z-1
	.footer-dropdown
		& + *
			@apply mt-4
	.footer-policy
		& + *
			@apply mt-3
