.site-nav-sticky
	@apply sticky top-24 md:top-20 z-40 xl:top-25

.site-nav-toggle
	@apply body-16
	@apply font-bold text-white bg-primary-500 py-3 px-6 flex justify-between
	@screen lg
		@apply hidden

.site-nav-list
	@apply absolute top-full left-0 w-full z-10 bg-white flex flex-col px-5 opacity-0 pointer-events-none
	a
		@apply body-16
		@apply font-medium text-neutral-600 inline-block py-3 hover-underline hover:text-optional-3
	li
		& + li
			@apply border-t border-t-neutral-200
		&.active
			a
				@apply text-optional-3
	&.open
		@apply opacity-100 pointer-events-auto
	@screen lg
		@apply static bg-white px-0 flex-row gap-5 opacity-100 pointer-events-auto pt-1
		li
			@apply border-none
		&.open
			@apply opacity-100 pointer-events-auto
	@screen xl
		@apply justify-center
	@screen 2xl
		@apply gap-10

.about-bg
	background-position: center 0 !important
	background-size: contain !important

.about-1-section
	.site-block-content
		@apply gap-6 lg:py-15 lg:pr-4
	.image
		@apply aspect-[640/460]

.about-2-item
	@apply relative text-current flex flex-col gap-5
	.year
		@apply sub-header-24
		@apply font-bold text-black text-center
	.line
		@apply relative w-[3px] mx-auto h-20 bg-current
		&::before, &::after
			content: ''
			@apply w-4 h-4 rounded-full bg-current absolute left-1/2 -translate-x-1/2 pointer-events-none
		&::before
			@apply top-0
		&::after
			@apply bottom-0
	.brief
		@apply body-16
		@apply font-normal text-black text-center
	@screen lg
		@apply my-[calc(250/1920*100rem)]
		.line
			@apply h-[calc(176/1920*100rem)] relative -top-20
		.year
			@apply absolute top-full left-1/2 -translate-x-1/2 -mt-18 w-full
		.brief
			@apply absolute bottom-full left-1/2 -translate-x-1/2 w-full mb-25

.about-2-swiper
	@apply relative
	.swiper-line
		@apply absolute top-[18%] -translate-y-1/2 left-0 w-full h-[2px] bg-neutral-400 pointer-events-none lg:top-[15%]
	// &::before
	// 	content: ''
	// 	@apply absolute top-[18%] -translate-y-1/2 left-0 w-full h-[2px] bg-neutral-400 pointer-events-none lg:top-[15%]
	.swiper-slide
		&:nth-child(6n+1)
			@apply text-optional-1
		&:nth-child(6n+2)
			@apply text-optional-2
		&:nth-child(6n+3)
			@apply text-optional-3
		&:nth-child(6n+4)
			@apply text-optional-4
		&:nth-child(6n+5)
			@apply text-optional-5
		&:nth-child(6n+6)
			@apply text-optional-6
	@screen lg
		.swiper-line
			@apply top-1/2 before:left-0 after:right-0
			&:before, &::after
				content: ''
				@apply absolute top-1/2 -translate-y-1/2 w-3 h-3 rounded-full bg-neutral-400
		.swiper-slide
			&:nth-child(even)
				.about-2-item
					@apply flex-col-reverse
					.line
						@apply top-20
					.year
						@apply top-auto bottom-full -mb-18 mt-0
					.brief
						@apply top-full bottom-auto mb-0 mt-25
		.swiper-button.is-absolute
			.button-prev
				@apply -left-10
			.button-next
				@apply -right-10

.about-3-section
	.main-tabslet
		@apply bg-white p-5 max-w-[calc(640/1920*100rem)]
	.tabslet-tabs
		@apply flex items-center gap-5
		a
			@apply sub-header-20
			@apply font-bold text-neutral-300 hover-underline hover:text-primary-600
		li
			@apply relative last:before:hidden
			&::before
				content: ''
				@apply absolute top-1/2 -translate-y-1/2 -right-2 h-5 w-[2px] bg-neutral-300 pointer-events-none
			&.active
				a
					@apply text-primary-600
	.tabslet-content
		@apply mt-4
	.full-content
		@apply body-16
		@apply font-normal text-black
	.about-3-wrap
		@apply md:pb-[calc(250/1920*100rem)]
	@screen lg
		.main-tabslet
			@apply py-10

.about-5-item
	h3
		@apply sub-header-24 font-bold text-black mb-7

.accordion-item
	@apply border-b border-b-neutral-200
	.accordion-title
		@apply body-16
		@apply font-bold text-neutral-500 uppercase py-4 cursor-pointer
	.accordion-content
		@apply hidden body-16 font-normal text-black mb-4
	&.active
		.accordion-title
			@apply text-primary-600

.about-5-section
	.image
		@apply aspect-[640/460]

.about-6-item
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
	@apply bg-primary-50 p-4 flex justify-center flex-col gap-2 text-center
	.title
		@apply body-16
		@apply font-bold text-primary-600
	.brief
		@apply body-16
		@apply font-normal text-black

.about-7-item
	@apply flex flex-col gap-8 sm:flex-row sm:items-center
	.image
		@apply aspect-[303/228] sm:w-[calc(304/640*100%)]
	.caption
		@apply sm:flex-1
	.title
		@apply sub-header-24
		@apply font-bold text-black mb-1
	.brief
		@apply body-16
		@apply font-normal text-black
	.button
		@apply mt-6
