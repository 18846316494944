.recruitment-1-section
	.image
		@apply aspect-[720/518]

.recruitment-2-section
	.site-block
		@apply max-w-[calc(1098/1920*100rem)] mx-auto
	.site-title, .site-desc
		@apply text-white
	.site-desc
		@apply sub-header-24
		@apply font-normal

.recruitment-3-item
	@apply text-center flex flex-col gap-8
	.icon
		@apply flex-center
	.title
		@apply body-16
		@apply font-bold text-neutral-900
	.sub-title
		@apply body-16
		@apply font-normal text-neutral-900

.recruitment-list-section

.recruitment-page
	.news-list
		@apply hidden
