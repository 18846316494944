+form-text
	@apply bg-white border border-neutral-200 pl-5 pr-10 py-2 rounded-none text-base font-normal leading-normal text-neutral-900 w-full bg-no-repeat h-10 m-0
	@apply focus:outline-none focus:ring-2 focus:ring-optional-1 focus:ring-offset-0

+form-input
	@apply placeholder:text-neutral-500
	// @apply placeholder-shown:bg-white
	@apply disabled:bg-neutral-700/50 disabled:border-neutral-700/75 disabled:text-neutral-900
	@apply read-only:bg-neutral-700/50 read-only:border-neutral-700/75 read-only:text-neutral-900

select
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%23666666' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M6 8l4 4 4-4'/></svg>")
	@apply bg-right appearance-none

select[multiple]
	@apply min-h-[calc(48/1920*100rem)] py-3 h-auto bg-none

textarea
	@apply h-30 py-2

input[type='file']
	@apply hidden
	~ label
		@apply relative w-max bg-neutral-600 border-none h-12 px-6 py-3 text-base text-primary-600 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-700

input[type='checkbox']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f14a']

input[type='radio']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-800 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f192']

.form-group
	@apply relative
	> label
		@apply relative block text-base font-normal leading-normal text-neutral-800
	[class*='fa-ex']
		@apply text-[12px] text-red-700 italic block mt-1 font-sans font-normal

.qradio

.qcheckbox

.qselect

.qnote

// button submit
.frm-btnwrap
	label.label, .frm-btn-reset
		@apply hidden
	input[type='submit']
		@apply btn btn-solid rounded-full transition-all cursor-pointer

// captcha
.frm-captcha
	@apply flex flex-wrap gap-5 mt-5 lg:flex-row-reverse lg:justify-end
	label
		@apply hidden
	.frm-captcha-input
		@apply w-full lg:w-auto
		input
			@apply w-full h-10 xl:max-w-[calc(260/1920*100rem)]
	.RadCaptcha
		width: max-content !important
		@apply flex flex-col-reverse
		> div
			@apply w-full
			> div
				@apply flex items-center
		> span
			@apply text-[12px] text-optional-1 italic block mt-1 font-sans font-normal
	.rcRefreshImage
		font-size: 0
		&::before
			@apply font-awesome content-['\f021'] font-light text-2xl text-neutral-400 ml-5 xl:text-40

.RadUpload
	@apply max-w-full
	input[type="file"]
		@apply block
	.ruInputs
		.ruRemove
			@apply text-0 w-auto
		li:not(:last-child)
			@apply flex items-center
		li
			&:last-child
				@apply mb-0
				.ruFileWrap
					@apply block w-full h-25 rounded-3 border border-dashed border-neutral-700 bg-white p-3 flex-center
					input
						@apply w-full h-full bg-white border-none text-main text-center

.apply-frm
	.apply-frm-title
		@apply sub-header-24
		@apply font-bold text-black text-center py-4 bg-neutral-50
	.apply-frm-wrap
		@apply px-8 max-w-[calc(896/1920*100rem)] mx-auto py-5 md:py-10
	.form-group
		@apply mb-8 last:mb-0
		label
			@apply text-base w-full text-main font-normal mb-1
			span
				@apply text-optional-1 text-sm
		input:not([type="button"]), textarea
			@apply border-neutral-200
	.attachfile1
		@apply relative
		label
			@apply h-[40px] border border-neutral-200 font-normal text-neutral-500 px-3 flex items-center text-base w-full max-w-[calc(100%-(32/1920*100rem))] m-0 absolute top-0 left-4 z-2 pointer-events-none bg-white
		.RadUpload
			@apply w-full rounded-none border-none max-w-full
			.ruInputs
				@apply flex flex-col-reverse
				li
					&:last-child
						.ruFileWrap
							@apply h-[40px] rounded-none border-none cursor-pointer
	.frm-btn
		@apply w-full flex justify-end
	.wrap-form
		@apply relative
	.form-group
		input
			@apply md:h-[40px]
		textarea
			@apply md:h-[120px]
	@screen md
		.form-group
			&.frm-captcha
				@apply items-center flex-row-reverse justify-end
				.frm-captcha-input
					@apply w-auto
				.RadCaptcha
					@apply relative
					> span
						@apply absolute top-full left-0 mt-1
			&.frm-btnwrap
				@apply absolute bottom-0 right-0
	@screen lg
		.form-group
			@apply flex gap-8
			label
				@apply w-[30%] mb-0 text-base
			input:not([type="button"])
				@apply h-10 text-base
			textarea
				@apply h-25 text-base
