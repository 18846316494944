header
	@apply sticky top-0 left-0 w-full z-999 bg-white transition-all shadow-light
	// .header-sticky
	// 	@apply bg-white transition-all shadow-light
	.header-wrap
		@apply flex px-0
	.header-left
		@apply w-1/4 max-w-[calc(212/1920*100rem)] bg-optional-9 p-4 flex-center
	.header-right
		@apply flex-1
	.header-top
		@apply flex items-center flex-wrap gap-4 p-2
	.header-bot
		@apply bg-neutral-50 h-full hidden
	.header-caption
		// @apply hidden
		// @apply absolute top-full bg-white left-0 w-full px-4 py-2
	.company-name
		@apply sub-header-20
		@apply font-bold text-optional-3 text-[10px]
	.company-sub-name
		@apply body-16
		@apply font-medium text-primary-600 -mt-[2px] text-[9px]
	.header-language-search
		@apply flex items-center justify-end gap-4 w-full
	.language-list
		@apply flex items-center gap-4
		a
			@apply body-16
			@apply font-normal text-neutral-500 hover-underline
		li
			&.active
				a
					@apply text-primary-600
	.header-search
		@apply relative ml-4 w-8 h-8 rounded-1 flex-center text-primary-600 cursor-pointer hover:text-primary-700 transition-all hover:bg-neutral-100
		&::before
			content: ''
			@apply absolute top-1/2 -translate-y-1/2 -left-4 w-px h-3 bg-neutral-300/50 pointer-events-none
	.main-menu
		@apply flex items-center justify-between
		a
			@apply body-16
			@apply font-bold text-black flex-center h-full py-2 hover-underline uppercase hover:text-optional-3
		li
			&.active
				> a
					@apply text-optional-3 hover-underline-active
			&.has-dropdown
				@apply relative
				&:hover
					.dropdown-menu
						@apply opacity-100 pointer-events-auto translate-y-0
		.dropdown-menu
			@apply absolute top-full left-0 w-max bg-white space-y-2 px-3 py-8 opacity-0 pointer-events-none -translate-y-3 transition-all shadow
			a
				@apply body-16
				@apply justify-start
	&.active
		@apply shadow
		// .header-sticky
			// @apply fixed top-0 left-0 w-full z-999 animate-header shadow
	@screen sm
		.header-left
			@apply w-1/3
		.company-name
			@apply text-[13px]
		.company-sub-name
			@apply text-[12px]
	@screen md
		.header-wrap
			@apply px-4 items-center
		.header-left
			@apply w-1/5
		.header-top
			@apply flex-nowrap justify-between p-0
		.header-caption
			@apply static p-0 pl-5 bg-transparent
		.header-language-search
			@apply w-auto
	@screen lg
		.header-left
			@apply w-[calc(212/1312*100%)] h-25
		.header-right
			@apply border-r border-r-neutral-200 flex flex-col
		.header-top
			@apply py-2 px-8 justify-between
		.header-bot
			@apply block px-8 relative
			&::before
				content: ''
				@apply absolute bottom-0 left-0 w-full z-1 h-px bg-neutral-200 pointer-events-none
		.header-caption
			@apply block p-0
		.company-name
			@apply sub-header-20
		.company-sub-name
			@apply body-16
		.navbar-nav
			@apply relative z-2
		.main-menu
			> li > a
				@apply h-10
	@screen 2xl
		.main-menu
			li
				@apply relative last:before:hidden
				&::before
					content: ''
					@apply absolute top-1/2 -translate-y-1/2 -right-6 w-1 h-1 bg-neutral-200 pointer-events-none
				li
					@apply before:hidden

.mobile-wrap
	@apply fixed top-0 w-screen h-screen bg-white shadow z-998 pt-28 pl-4 pb-4 max-w-xs -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none lg:hidden
	.header-caption
		@apply pr-5
	.company-name
		@apply sub-header-20
		@apply font-bold text-optional-3
	.company-sub-name
		@apply body-16
		@apply font-medium text-primary-600 -mt-[2px]
	.navbar-nav-list
		@apply pr-4 mt-5
	.main-menu
		a
			@apply body-16
			@apply font-bold text-black flex py-2 uppercase
		li
			&.active
				> a
					@apply text-optional-3
			&.has-dropdown
				.dropdown-menu
					@apply pl-5 mb-3
					a
						@apply body-14 text-[13px] normal-case
	&.open
		@apply left-0 opacity-100 pointer-events-auto

.search-wrap
	@apply fixed top-1/3 -translate-y-1/2 left-1/2 -translate-x-1/2 w-full max-w-md z-998 px-6 opacity-0 pointer-events-none transition
	&.open
		@apply opacity-100 pointer-events-auto

// button toggle menu
#buttonMenu
	@apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none
	.line
		@apply absolute block h-[2px] w-full bg-primary-600 rounded-full opacity-100 left-0 rotate-0 transition-all
		&:nth-child(1)
			@apply top-[4px]
		&:nth-child(2)
			@apply top-1/2 -translate-y-1/2
		&:nth-child(3)
			@apply bottom-[4px]
	#pulseMe
		@apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1
	.bar
		@apply bg-primary-600 rounded-1 absolute shadow-bar
		&.left
			@apply w-px animate-left-bar
		&.top
			@apply h-px animate-top-bar
		&.right
			@apply w-px animate-right-bar
		&.bottom
			@apply h-px animate-bottom-bar
	&.open, &:hover
		.line
			&:nth-child(2)
				@apply w-[15px]
		.bar
			@apply hidden
	@screen lg
		display: none !important
