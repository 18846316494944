.welfare-item
	@apply flex items-center gap-2

.welfare-list
	@apply grid grid-cols-2 gap-4 xl:grid-cols-3

.recruitment-other-item
	@apply flex flex-col gap-4
	& + .recruitment-other-item
		@apply mt-4
	.title
		@apply body-16
		@apply font-normal text-black line-clamp-2 hover:text-optional-3
		&:hover
			text-shadow: 1px 0 0 currentColor
	ul
		@apply flex flex-wrap gap-x-6 gap-y-3
	li
		@apply body-16
		@apply font-normal text-neutral-400 flex items-center gap-2

.box-recruitment-other
	.box-title
		@apply body-16
	.box-body
		@apply p-5 xl:px-8

.recruitment-detail-section
	.recruitment-image
		@apply aspect-[640/460]
	.recruitment-information
		table
			@apply w-full
		td, th
			@apply body-16
			@apply font-normal text-neutral-900 py-4 border-b border-b-neutral-50 align-top first:w-1/3 first:font-bold first:pr-1 xl:first:w-1/4
	.recruitment-wrap
		& + .recruitment-wrap
			@apply mt-11
	.recruitment-attributes
		@apply body-16
		@apply font-normal text-black
		> *
			@apply mb-2 last:mb-0
		h3
			@apply sub-header-20
			@apply font-bold text-primary-600 mb-1
		h4
			@apply sub-header-24
			@apply font-bold text-black uppercase mt-6 mb-2
		& + .recruitment-attributes
			@apply mt-8
		& + .button
			@apply mt-10 gap-8
	.box-recruitment-other
		@apply xl:mt-10 xl:sticky xl:top-30

.recruitment-detail-page
	.banner-child
		@apply hidden
