.news-date
	@apply body-16
	@apply font-medium text-neutral-500

.category-date
	@apply body-16
	@apply font-medium text-neutral-500 flex gap-3
	.category
		@apply relative
		&::before
			content: '|'
			@apply absolute top-1/2 -translate-y-1/2 -right-2 pointer-events-none

.news-item
	@apply flex flex-col
	.image
		@apply aspect-[416/299]
	.caption
		@apply h-full flex flex-col gap-2 pl-3 pr-7 py-5
	.title
		@apply sub-header-20
		@apply font-bold text-black line-clamp-2 hover:text-primary-600
	.brief
		@apply body-16
		@apply font-normal text-black mt-2 line-clamp-2
	&.is-hor
		@apply flex-row gap-0
		& + .news-item
			@apply mt-5 lg:mt-8
		.image
			@apply w-[calc(160/1920*100rem)] aspect-[160/120]
		.caption
			@apply flex-1 p-0 pl-5 border-b border-b-neutral-200 h-auto lg:pl-8
		.title
			@apply line-clamp-3
	&.is-big
		.image
			@apply aspect-[624/448]
		.brief
			@apply line-clamp-3
		@screen lg
			.title
				@apply section-header
				@apply font-semibold text-neutral-950 hover:text-primary-600
			.caption
				@apply px-0 py-8
	&.is-other
		.caption
			@apply px-1

.news-list
	@screen lg
		.col-l
			@apply pr-8
		.col-r
			@apply pl-8 border-l border-l-neutral-200

.site-nav-animation
	@apply -mt-5
	ul
		@apply flex-center gap-3 mb-10 xl:gap-6 xl:mb-15
		> li
			@apply relative
			> a
				border: solid 4px theme('colors.optional.9')
				@apply flex-center body-16 font-bold text-optional-3 bg-optional-9 px-5 py-2 rounded-full shadow-light text-center
				@apply hover:text-primary-600
				&.is-animation
					--border-angle: 0turn
					--main-bg: conic-gradient(from var(--border-angle),#FFF500,#FFF500 5%,#FFF500 60%,#FFF500 95%)
					border: solid 4px transparent
					border-radius: 2em
					--gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #1B5EB2, #E41913 99%, transparent)
					background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box
					background-position: center center
					animation: bg-spin 3s linear infinite
					@keyframes bg-spin
						to
							--border-angle: 1turn
					@apply shadow-none
					&:hover
						animation-play-state: paused
			&:hover
				> ul
					@apply opacity-100 pointer-events-auto
			> ul
				@apply flex-col justify-start items-start mb-0 gap-0 absolute top-full left-0 w-max bg-white shadow z-10 py-3 rounded-1 transition-all duration-200 ease-in-out opacity-0 pointer-events-none
				li
					@apply w-full before:hidden
				a
					@apply border-0 bg-none bg-transparent justify-start shadow-none text-neutral-950 transition-all duration-200 ease-in-out text-left hover:text-optional-3
	@screen xl
		li
			& + li
				@apply relative
				&::before
					content: ''
					@apply absolute top-1/2 -translate-y-1/2 -left-3 pointer-events-none w-px h-5 bg-neutral-200 block

@property --border-angle
	syntax: "<angle>"
	inherits: true
	initial-value: 0turn
