.dropdown
	@apply relative
	.dropdown-toggle
		@apply body-16
		@apply font-bold text-white flex items-center justify-between gap-4 cursor-pointer
		i
			@apply text-lg
	.dropdown-menu
		@apply absolute top-full right-0 w-full min-w-max z-10 bg-white shadow opacity-0 pointer-events-none transition-all
		li
			& + li
				@apply border-t border-t-neutral-200
		a
			@apply sub-header-20
			@apply font-normal text-black px-5 py-3 inline-block hover:text-primary-600
			&:hover
				text-shadow: 1px 0 0 currentColor
	&:hover
		.dropdown-menu
			@apply opacity-100 pointer-events-auto
