.heading-flex
	@apply flex flex-wrap justify-between gap-5

.link-download
	@apply sub-header-20
	@apply font-normal text-optional-7 relative
	a
		@apply whitespace-nowrap flex items-center gap-2 hover:text-primary-600
		&::before
			@apply font-awesome content-['\f019'] font-light text-2xl leading-none mr-1

.shareholder-item
	@apply p-5 bg-white shadow-light rounded-5 flex flex-col justify-between gap-5 xl:min-h-[calc(304/1920*100rem)]
	.top
		@apply flex flex-col gap-5
	.icon
		@apply h-15 flex justify-start
		svg
			@apply h-full
	.title
		@apply body-16
		@apply font-normal text-black hover:text-primary-600
		a
			@apply hover-underline
	@screen lg
		@apply px-10 py-7
		.icon
			@apply h-23
	@screen xl
		&.is-hor
			box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08)
			@apply flex-col min-h-0 p-7 pb-6
			.top
				@apply flex-row gap-5 items-start
			.icon
				@apply h-15
			.link-download
				@apply text-sm text-neutral-500
				a
					@apply before:text-sm

.shareholder-1-section
	@screen xl
		.table-responsive
			box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.08)
			td
				@apply first:whitespace-nowrap

.shareholder-3-item
	@apply flex flex-col gap-10 bg-white
	.image
		@apply aspect-[375/540]
	.content
		@apply flex-1 p-5 pb-12
	.heading-flex
		@apply mb-10
	@screen md
		@apply flex-row items-center
		.image
			@apply w-[calc(375/1920*100rem)]
		.content
			@apply p-0 pr-7


.shareholder-3-box
	@apply bg-neutral-50
	&:nth-child(2n)
		@apply bg-white
		.shareholder-3-item
			@apply bg-primary-600 md:flex-row-reverse
			.section-header
				@apply text-white
			.content
				@screen md
					@apply p-0 pl-7
