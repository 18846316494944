.service-1-item
	@apply relative
	.image
		@apply aspect-[480/324]
		img
			@apply w-full h-full object-cover
	.caption
		@apply relative z-1 -mt-17 flex flex-col gap-3 justify-center px-5 py-3 bg-primary-600/80
	.title
		@apply sub-header-24 font-bold text-white
		@apply leading-[1.33] h-10 line-clamp-1 flex items-center
	.desc
		@apply body-14 font-normal text-white
		@apply leading-[1.28] pt-5
		ul
			@apply list-disc
			li
				@apply marker:text-[10px]
	&.is-video
		@apply cursor-pointer
		&::before
			@apply absolute-center z-1 pointer-events-none font-awesome content-['\f04b'] text-32 font-thin text-white xl:text-64 transition-all duration-200 ease-in-out
		.caption
			@apply pointer-events-none
	@screen xl
		.caption
			@apply absolute bottom-0 left-0 w-full h-[107px] py-2 px-10 m-0 transition-all duration-200 ease-in-out
			&:hover
				@apply h-full
				.toggle-hidden
					grid-template-rows: 1fr
		.title
			@apply h-auto line-clamp-2
		.desc
			@apply pt-0

.toggle-hidden
	@screen xl
		grid-template-rows: 0fr
		transition: 250ms grid-template-rows ease
		@apply grid
		> div
			@apply overflow-hidden

.service-1-list
	@apply grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-5 lg:grid-cols-3 xl:grid-cols-4 xl:gap-0

.service-detail-section
	.site-title
		@apply header-40 text-primary-600
	.image
		@apply aspect-[640/432]
	.block-content
		@apply lg:max-w-[544px] lg:ml-auto lg:mr-0
	.full-content
		@apply body-16 text-black
		ul
			@apply pl-4
		li
			@apply marker:text-sm marker:text-black

.service-detail-other-section
	.site-title
		@apply header-40 text-primary-600
	.service-1-item
		@screen xl
			.title, .desc
				@apply max-w-[395px] mx-auto
