.swiper-pagination
	.swiper-pagination-bullet
		@apply opacity-100 w-3 h-3 rounded-full bg-optional-1/50
		&.swiper-pagination-bullet-active
			@apply bg-optional-1
	&.is-white
		.swiper-pagination-bullet
			@apply bg-white/50
			&.swiper-pagination-bullet-active
				@apply bg-white

.swiper-button
	> *
		@apply text-2xl text-primary-600 w-15 h-15 rounded-2 border border-primary-600 bg-transparent flex-center transition-all cursor-pointer hover:text-white hover:bg-primary-600
		&.swiper-button-disabled
			@apply opacity-40 cursor-default hover:text-primary-600 hover:bg-transparent
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	&.is-white
		> *
			@apply text-white bg-white/20 border-white hover:text-neutral-200
	&.is-absolute
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-1
		.button-prev
			@apply left-1
		.button-next
			@apply right-1
		&.is-top-20
			> *
				@apply top-[20%]
		&.is-top-30
			> *
				@apply top-[30%]
		&.is-top-40
			> *
				@apply top-[40%]
	&.is-angle
		> *
			@apply text-32 text-neutral-500 border-none w-auto h-auto rounded-none hover:text-primary-600 hover:bg-transparent
		&.is-absolute
			.button-prev
				@apply -left-10
			.button-next
				@apply -right-10

.swiper-relative
	@apply relative lg:px-10 lg:pb-0 xl:px-0
	.swiper-button
		&.is-absolute
			.button-prev
				@apply left-0 xl:-left-12 2xl:-left-22
			.button-next
				@apply right-0 xl:-right-12 2xl:-right-22
	.swiper-scrollbar
		@apply h-[2px] bg-neutral-300
		.swiper-scrollbar-drag
			@apply bg-optional-1
