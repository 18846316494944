.global-breadcrumb
	@apply bg-white border-b border-b-neutral-100
	.breadcrumb
		@apply flex flex-wrap items-center py-3 gap-x-3 gap-y-2
		a
			@apply body-16
			@apply block font-normal text-black
			&.active
				@apply text-optional-3
		li
			@apply flex items-center last:after:hidden
			&::after
				@apply font-awesome content-['\f054'] pl-3 text-black text-base leading-none
			// &::after
			// 	content: '/'
			// 	@apply pl-5 text-neutral-700 text-base leading-none
			// &:first-child
			// 	a
			// 		@apply text-0
			// 		span
			// 			@apply hidden
			// 		&::before
			// 			@apply font-awesome content-['\f015'] text-neutral-700 text-base font-bold leading-none flex-center
