.library-item
	@apply relative cursor-pointer
	.image
		@apply relative aspect-[256/192] bg-black
		img
			@apply opacity-70
	.caption
		@apply absolute bottom-0 left-0 w-full bg-black/30 px-5 py-3 text-center pointer-events-none
	.title
		text-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10)
		@apply sub-header-20
		@apply font-bold text-white line-clamp-2
		a
			@apply pointer-events-auto
	&.is-video
		.image
			@apply aspect-[192/128] bg-black
			&::before
				@apply absolute-center font-awesome content-['\f04b'] font-thin text-5xl text-white z-2
			img
				@apply opacity-60
		.caption
			@apply static bg-none bg-transparent px-0 py-2
		.title
			@apply text-neutral-950 hover:text-primary-600
	@screen lg
		.caption
			@apply px-8

.library-list
	@apply grid grid-cols-2 gap-4
	@screen md
		@apply gap-8 grid-cols-3
	@screen xl
		&.is-video
			@apply grid-cols-2

.library-section
	.tabslet-tabs
		@apply flex justify-center gap-5 md:gap-10
		a
			@apply sub-header-20 text-black font-bold hover:text-optional-3
		li
			&.active
				a
					@apply hover-underline-active text-optional-3
