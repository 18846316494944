.box
	.box-head
		@apply bg-primary-600 px-6 py-4
	.box-title
		@apply section-header
		@apply font-bold text-white
	.box-body
		@apply border-x border-b border-neutral-200

.box-category
	.category-list
		a
			@apply sub-header-20
			@apply font-normal text-black inline-block px-7 py-5 hover:text-primary-600
		li
			& + li
				@apply border-t border-t-neutral-200
			&.active
				a
					@apply text-primary-600 font-bold

.document-section
	.site-title
		@apply pb-5 pt-2 border-b border-b-neutral-200

.production-section
	.dvt
		@apply body-16
		@apply font-bold text-optional-3
	.table-responsive
		@media(max-width: 1023.89px)
			@apply overflow-visible
